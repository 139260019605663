div.err-nf-404 {
	width: 100%;
}

div.err-nf-404.cont {
	margin: 0;
	padding: 0;
	flex-direction: column;
	min-height: calc(100vh - 35px);

	background-image: url('https://listingopen-photos.s3.us-east-2.amazonaws.com/landing/orange_gradient_bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom center;
}

div.err-nf-404.top {
	margin: 35px auto 15vh auto;
	width: 100%;
	text-align: center;
}

div.err-nf-404.top > img, div.err-nf-404.mid > img {
	user-select: none;
}

div.err-nf-404.mid {
	width: 90%;
	padding: 0 5%;
	text-align: center;
}

div.err-nf-404.mid > img {
	height: 145px;
}

div.err-nf-404.mid > img.se-500 {
	height: 145px;
}

div.err-nf-404.mid > h1 {
	font-size: 32px;
	font-weight: 600;
	margin: 50px auto 0 auto;
}

div.err-nf-404.mid > h2 {
	font-size: 18px;
	font-weight: 400;
	margin: 15px auto 0 auto;
}
div.err-nf-404.mid > h2.se-500 {
	max-width: 680px;
}

div.err-nf-404.mid > div.flex {
	width: fit-content;
	margin: 35px auto 0 auto;
}

div.err-nf-404.mid > div.flex > a {
	margin: auto 20px 0 0;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    background-color: #000;
    padding: 10px 20px;
    display: flex;
    transition: ease-in-out 0.2s;
	user-select: none;
}

div.err-nf-404.mid > div.flex > a:hover {
	transform: scale(1.05);
}

div.err-nf-404.mid > div.flex > a:first-child {
	margin-right: 15px;
	background-color: black;
	color: white;
}

div.err-nf-404.mid > div.flex > a:last-child {
	border: 1px solid black;
    background-color: white;
    color: black;
	margin-right: 0;
}
 
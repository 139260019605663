/* Fonts */

@font-face {
  font-family: 'NBIP';
  src: url('./fonts/NBIP/NBInternationalPro-Reg.eot');
  src: url('./fonts/NBIP/NBInternationalPro-Reg.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NBIP/NBInternationalPro-Reg.woff2') format('woff2'),
      url('./fonts/NBIP/NBInternationalPro-Reg.woff') format('woff'),
      url('./fonts/NBIP/NBInternationalPro-Reg.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NBIP';
  src: url('./fonts/NBIP/NBInternationalPro-Lig.eot');
  src: url('./fonts/NBIP/NBInternationalPro-Lig.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NBIP/NBInternationalPro-Lig.woff2') format('woff2'),
      url('./fonts/NBIP/NBInternationalPro-Lig.woff') format('woff'),
      url('./fonts/NBIP/NBInternationalPro-Lig.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NBIP';
  src: url('./fonts/NBIP/NBInternationalPro-Bol.eot');
  src: url('./fonts/NBIP/NBInternationalPro-Bol.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NBIP/NBInternationalPro-Bol.woff2') format('woff2'),
      url('./fonts/NBIP/NBInternationalPro-Bol.woff') format('woff'),
      url('./fonts/NBIP/NBInternationalPro-Bol.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



/* Palette */

:root {
  --text-main: black;

  /* Greys */
  --grey-1: #fafafa;
  --grey-2: #eaeaea;
  --grey-3: #dddddd;
  --grey-4: #7b7b7b;
  --grey-5: #8b8b8b;
  --grey-6: #626262;
  --grey-7: #a5a5a5;
  --grey-8: #efefef;
  --grey-9: #f5f5f5;

  /* Accents */
  /*--orange-accent: #FD8D78;
  --orange-accent-lite: #FD8D7860;*/
  --orange-accent: #FF5C3C;
  --orange-accent-lite: #FCCFC7;
  --orange-accent-lite-2: #FFE3DE;
  --purple-accent: #8F6AEA;
  --purple-accent-lite: #e7e0f6;
  --blue-accent: #197FF5;
  --green-accent: #B0FF32;
  --delete-bg: #FF8886;
  --delete-bg-fade: #FF888670;
  --delete-fg: #8B0000;

  /* Sign In Background */
  --si-dark-bg: #2E2E2E;
  --si-light-bg: white;

  /* Sign In Foreground */
  --si-fg-black-main: #000000;
  --si-fg-black-fade: #00000070;
  --si-fg-black-secondary: #000000;
  --si-fg-silver-main: #6D7986;
  --si-fg-silver-fade: #6D798670;
  --si-fg-silver-secondary: #4b545d;
  --si-fg-navy-main: #033459;
  --si-fg-navy-fade: #03345970;
  --si-fg-navy-secondary: #012038;
  --si-fg-blue-main: #3F70DE;
  --si-fg-blue-fade: #3F70DE70;
  --si-fg-blue-secondary: #3054a5;
  --si-fg-maroon-main: #890539;
  --si-fg-maroon-fade: #89053970;
  --si-fg-maroon-secondary: #5a0526;
  --si-fg-red-main: #C33641;
  --si-fg-red-fade: #C3364170;
  --si-fg-red-secondary: #8b272f;
  --si-fg-orange-main: #EB541C;
  --si-fg-orange-fade: #EB541C70;
  --si-fg-orange-secondary: #b43c10;
  --si-fg-gold-main: #BEB185;
  --si-fg-gold-fade: #BEB18570;
  --si-fg-gold-secondary: #9a8e65;
  --si-fg-lime-main: #B7C096;
  --si-fg-lime-fade: #B7C09670;
  --si-fg-lime-secondary: #767c61;
  --si-fg-green-main: #205A2B;
  --si-fg-green-fade: #205A2B70;
  --si-fg-green-secondary: #153b1c;
  --si-fg-aqua-main: #1EBEA6;
  --si-fg-aqua-fade: #1EBEA670;
  --si-fg-aqua-secondary: #13796a;
  --si-fg-purple-main: #815A88;
  --si-fg-purple-fade: #815A8870;
  --si-fg-purple-secondary: #523957;
}

/* General Styles */

html, body, #root {
  width: 100%;
  /* height: 100%; */
  margin: 0;
  background-color: var(--body-bg);
}

h1 {
  font-family: 'NBIP', 'Inter', sans-serif;
  color: var(--text-main);
}

h2, h3, .inter {
  font-family: 'Inter', sans-serif;
}

a, button {
  cursor: pointer;
  outline: none;
}

[src=''] {
    visibility: hidden;
}

.hidden {
  opacity: 0.3 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

h1.pg-t {
  margin: 0;
  font-size: 22px;
  font-family: 'NBIP';
  font-weight: 600;
  color: black;
}

div.flex {
  display: flex !important;
}

div.iflex {
  display: inline-flex !important;
}

input:focus, textarea:focus {
  outline-color: var(--orange-accent);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Do not group placeholder color styles */
*::-webkit-input-placeholder {
  color: var(--grey-7);
}
*:-moz-placeholder {
  /* FF 4-18 */
  color: var(--grey-7);
  opacity: 1;
}
*::-moz-placeholder {
  /* FF 19+ */
  color: var(--grey-7);
  opacity: 1;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--grey-7);
}
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--grey-7);
}
*::placeholder {
  /* modern browser */
  color: var(--grey-7);
}

span.orange { color: var(--orange-accent); }
span.tm { color: var(--text-main); }
span.crs { cursor: pointer; }
span.ul { text-decoration: underline; }

div.prl-spin-cont {
  margin: calc(50vh - 200px) auto 0 auto;
  text-align: center;
}
div.prl-spin-cont.npt { margin-top: calc(50vh - 30px); }
div.prl-spin-cont.nt {
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  margin: 0;
}
div.prl-spin {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: radial-gradient(farthest-side,#ff5c3c 94%,#0000) top/3.4px 3.4px no-repeat,
        conic-gradient(#0000 30%,#ff5c3c);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 3.4px),#000 0);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.4px),#000 0);
  animation: prl-anim 0.8s infinite linear;
  -webkit-animation: prl-anim 0.8s infinite linear;
  -moz-animation: prl-anim 0.8s infinite linear;
  -o-animation: prl-anim 0.8s infinite linear;
}

@keyframes prl-anim {
  100% {
     transform: rotate(1turn);
  }
}

/* Dashboard Auto Layout */

div.content {
  width: 100%;
  height: 100%;
  min-height: 100%;
  bottom: 0;
}

div.cont-r {
  width: calc(100% - 65px);
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 65px;
  position: absolute;
}

div.content-spacer {
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: var(--grey-2);
  margin: 20px 0 20px 0;
}


/* Top Bar */

div.tb-cont {
  margin: 0;
  padding: 45px 0 0 0;
  background-color: white;
  border-bottom: 1px solid var(--grey-3);
  display: block;
}

div.tb-top {
  margin: 0 70px 30px 70px;
  width: calc(100% - 140px);
}

div.tb-nav {
  margin-left: 61px;
}

img.tb-t-icon {
  margin: auto 10px auto 0;
}

div.tb-top button, div.tb-top a {
  width: fit-content;
  padding: 0 20px 0 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  text-decoration: none;
  align-items: center;
  display: flex;
}

div.tb-top a > h3 {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: auto;
  text-decoration: none;
}

div.tb-nav {
  margin-left: 61px;
}

div.tb-nav > button {
  width: auto;
  border: none;
  background-color: transparent;
  margin: 0 30px 0 0;
  padding: 0;
}

div.tb-nav > button:last-child {
  margin-right: 0;
}

div.tb-nav > button > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto 12px auto;
}

div.tb-nav > button > div {
  height: 3px;
  width: 65px;
  background-color: transparent;
  margin: 0 auto 0 auto;
}

div.tb-nav > button > div.a {
  background-color: var(--orange-accent);
}

div.tb-steps-cont {
  margin: 30px 0 0 0;
}

div.tb-steps {
  width: fit-content;
  height: fit-content;
  margin: 0 20px 0 0;
}

div.tb-steps:last-child { margin: 0; }

div.tb-steps > h2 {
  margin: 4px 0 auto 0;
  font-size: 15px;
  font-family: 'NBIP';
  font-weight: 500;
  color: var(--grey-4);
}
div.tb-steps.active > h2 { color: black; }

div.tb-step-num {
  width: 20px;
  height: fit-content;
  padding: 3px 0 3px 0;
  border: none;
  border-radius: 3px;
  background-color: var(--grey-3);
  margin: 0 8px 0 0;
}
div.tb-steps.active > div.tb-step-num { background-color: var(--orange-accent-lite); }

div.tb-step-num > h3 {
  font-size: 13px;
  color: var(--grey-4);
  text-align: center;
  margin: 0;
}
div.tb-steps.active > div.tb-step-num > h3 { color: var(--orange-accent); }


/* Error Messages */

div.app-err-cont {
  width: 100%;
  position: fixed;
  height: 50px;
  z-index: 20;
  bottom: 30px;
  text-align: center;
}

div.app-err-d {
  background-color: #DA1D27;
  border-radius: 5px;
  width: 90%;
  max-width: 600px;
  height: 50px;
  padding: 0;
  display: flex;
  margin: 0 auto 0 auto;
}

div.app-err-d > img {
  height: 18px;
  margin: auto 12px auto 15px;
}

div.app-err-d > h3 {
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
  margin: auto auto auto 0;
}

div.app-err-d > button {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
}
div.app-err-d > button > img {
  height: 13px;
  width: 13px;
  margin-top: 2px;
}


/* Dashboard Basics */

div.dash-result-d {
  width: fit-content;
  margin: 0 auto 25px 0;
}

div.dash-result-d > h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}

div.dash-result-num {
  background-color: var(--grey-3);
  width: fit-content;
  padding: 0 5px 0 5px;
  height: 20px;
  border: none;
  border-radius: 3px;
  margin: 0 0 0 6px;
  display: flex;
  align-items: center;
}

div.dash-result-num > h3 {
  margin: auto;
  font-size: 13px;
  color: var(--grey-4);
}

div.dash-cont-b-d {
  height: fit-content;
  width: fit-content;
  margin: 65px auto 0 0;
}
div.dash-cont-b-d.wd { width: 100%; }

div.dash-cont-b-d button {
  width: fit-content;
  padding: 0 20px 0 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin: 0 15px 0 0;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: var(--grey-4);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
div.dash-cont-b-d button:last-child { margin: 0; }
div.dash-cont-b-d button.back { border: 1px solid black; background-color: transparent; color: var(--text-main); }
div.dash-cont-b-d button.next { background-color: black; color: white; padding: 0 25px 0 25px; }
div.dash-cont-b-d button.finish { background-color: var(--orange-accent); color: white; padding: 0 25px 0 25px; }
div.dash-cont-b-d button.enhance { background-color: #D4E5FA; color: #0E4FA7; }
div.dash-cont-b-d button.del { background-color: var(--delete-bg-fade); color: var(--delete-fg); }
div.dash-cont-b-d button.retry { background-color: var(--grey-8); color: var(--grey-4); }

div.dash-cont-b-d > div.r { 
  width: fit-content;
  height: fit-content;
  margin: 0 0 0 auto;
}


/* Sidebar */

div.sb-cont {
  width: 65px;
  height: 100vh;
  background-color: white;
  border-right: 1px solid var(--grey-2);
  left: 0;
  top: 0;
  position: fixed;
  z-index: 2;

  display: inline;
  align-items: center;
  justify-content: center;
  margin: auto;
}

div.sb-logo-d {
  margin: 30px auto 45px auto;
}

div.sb-logo-d > img {
  margin: 0 auto 0 auto;
  display: block;
}

a.sb-nl {
  display: block;
  margin-bottom: 30px;
}

div.sb-icon-d {
  width: 100%;
  height: 25px;
}

div.sb-icon-d > img {
  height: auto;
  width: auto;
  margin: auto;
}

div.sb-icon-act {
  display: none;
  width: 5px;
  height: 20px;
  background-color: var(--orange-accent);
  margin: auto 0 auto auto;
}

a.sb-nl.active > div.sb-icon-d {
  width: calc(100% - 10px);
  margin-left: 5px;
  border-right: 5px solid var(--orange-accent);
}

div.sb-spacer {
  margin: 0 auto 30px auto;
  height: 2px;
  width: 25px;
  background-color: var(--grey-3);
  border-radius: 5px;
}


/* Sign Up Page */

div.su-bg {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: var(--orange-accent);
}
div.su-bg > img {
  width: calc(100% - 450px);
  height: 100%;
  object-fit: cover;
  object-position: center top;

  /* Unselectable */
  pointer-events: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

div.su-cont {
  padding: 45px;
  width: calc(100% - 90px);
  height: calc(100% - 90px);
  max-width: 410px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border: none;
}

div.su-cont > div.top { margin: 0; }

div.su-cont > div.top > img {
  height: 31px;
  margin: 0;
}
div.su-cont > div.top.bl > img { width: auto; margin-left: 0px; }

div.su-cont > div.top > h1 {
  margin: auto auto 2px 15px;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
div.su-cont > div.top.bl > h1 { margin: 25px auto 0 0; }

div.su-cont > div.in {
  margin: 30px 0 0 0;
}

div.su-cont > div.in > div.di {
  margin: 0 0 30px 0;
  width: 100%;
  height: fit-content;
}
div.su-cont > div.in > div.di.mb0 { margin-bottom: 0; }

div.su-cont > div.in > div.di.plans {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-gap: 10px;
}
div.su-cont > div.in > div.di.plans > div {
  height: 40px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  cursor: pointer;
}
div.su-cont > div.in > div.di.plans > div.sel { border-color: black; }
div.su-cont > div.in > div.di.plans > div.hidden { opacity: 0.5; }
div.su-cont > div.in > div.di.plans > div > h2 {
  margin: auto auto auto 0;
  font-size: 15px;
  font-weight: 500;
}
div.su-cont > div.in > div.di.plans > div > img {
  height: 20px;
  width: 20px;
  margin: 10px 8px 10px 10px;
}

div.su-cont > div.in > div.di > div.ft {
  width: 100%;
  height: fit-content;
  margin: 0;
}
div.su-cont > div.in > div.di > div.ft.mt { margin-top: 15px; }
div.su-cont > div.in > div.di > div.ft.hidden { opacity: 0.5; }

div.su-cont > div.in > div.di > div.ft > div {
  margin: 0 0 3px 0;
}

div.su-cont > div.in > div.di > div.ft > div > img {
  margin: auto 10px auto 0;
  height: 8px;
  width: 11px;
}
div.su-cont > div.in > div.di > div.ft > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}

div.su-cont > div.in > div.di > h2, div.su-cont > div.in > div.di > div.vc.flex > h2, div.su-cont > div.in > div.di > div.su-pws > h2 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

div.su-cont > div.in > div.di > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 0 0 30px 0;
  line-height: 20px;
}
div.su-cont > div.in > div.di.price > h3 { margin: 0 0 0 auto; }
div.su-cont > div.in > div.di.price > h3.orange { color: var(--orange-accent); }
div.su-cont > div.in > div.di > h3 > span { color: var(--text-main); text-decoration: underline; font-weight: 500; }

div.su-cont > div.in > div.di.bill > div {
  width: 100%;
  height: 50px;
  margin: 0 0 10px 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  cursor: pointer;
}
div.su-cont > div.in > div.di.bill > div.sel { border-color: black; }
div.su-cont > div.in > div.di.bill > div:last-child { margin-bottom: 0; }

div.su-cont > div.in > div.di.bill > div > h2 {
  margin: auto auto auto 15px;
  font-size: 15px;
  font-weight: 500;
}

div.su-cont > div.in > div.di.bill > div > h3 {
  margin: auto 15px auto auto;
  font-size: 15px;
  font-weight: 400;
  color: var(--orange-accent);
}
div.su-cont > div.in > div.di.bill > div > h3 > span { color: var(--grey-7); text-decoration: line-through; } 

div.su-cont > div.in > div.di > input, div.su-cont > div.in > div.di > div.split > input {
  margin: 10px 0 0 0;
  height: 40px;
  padding: 0 10px;
  width: calc(100% - 22px);
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  color: var(--text-main) !important;

  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
}
div.su-cont > div.in > div.di > input.err, div.su-cont > div.in > div.di > div.split > input.err {
  animation: shake 0.2s ease-in-out 0s 2;
}
div.su-cont > div.in > div.di > input.vc {
  font-size: 30px;
  height: 60px;
  letter-spacing: 5px;
  padding: 0 17px;
  width: calc(100% - 36px);
}
div.su-cont > div.in > div.di > input.vc.err { border-color: var(--si-fg-red-main); }
div.su-cont > div.in > div.di > input.vc.err:focus { outline-color: var(--si-fg-red-main); }

div.su-cont > div.in > div.di > div.vc.flex { width: 100%; }
div.su-cont > div.in > div.di > div.vc.flex > button {
  width: fit-content;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: var(--orange-accent);
  text-decoration: underline;
  margin: 0 0 0 auto;
}
div.su-cont > div.in > div.di > div.vc.flex > button.timer {
  text-decoration: none;
  color: var(--grey-4);
}

div.su-cont > div.in > div.di > h3.vc-err {
  color: var(--delete-fg);
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0 0;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}

div.su-cont > div.in > div.di > div.split > input {
  width: calc(50% - 5px);
  margin-right: 10px;
}
div.su-cont > div.in > div.di > div.split > input:last-child { margin-right: 0px; }

div.su-cont > div.accept {
  position: absolute;
  width: calc(100% - 90px);
  bottom: 125px;
  top: auto;
}

div.su-cont > div.accept > div {
  width: 100%;
  margin: 0 0 15px 0;
}
div.su-cont > div.accept > div:last-child { margin-bottom: 0px; }

div.su-cont > div.accept > div > button {
  height: 20px;
  width: 20px;
  padding: 0;
  background-color: white;
  border: 1px solid var(--grey-4);
  border-radius: 3px;
}
div.su-cont > div.accept > div > button > img {
  margin-bottom: 1px;
}
div.su-cont > div.accept > div > button.sel {
  border: var(--orange-accent);
  background-color: var(--orange-accent);
}

div.su-cont > div.accept > div > h3 {
  font-size: 13px;
  font-weight: 400;
  color: var(--grey-4);
  margin: auto 0 auto 10px;
  width: calc(100% - 30px);
}

div.su-cont > button, div.su-cont > div.su-bd > button {
  padding: 0;
  height: 50px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: black;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
div.su-cont > button, div.su-cont > div.su-bd {
  position: absolute;
  bottom: 45px;
  top: auto;
  width: calc(100% - 90px);
}
div.su-cont > button.tb { bottom: 110px; }
div.su-cont > button.su, div.su-cont > div.su-bd > button.su {
  border: 1px solid black;
  background-color: transparent;
  color: black;
}

div.su-cont > div.su-bd > button {
  flex: 2.5;
  margin: 0 0 0 auto;
}
div.su-cont > div.su-bd > button.su {
  flex: 1;
  margin: 0 15px 0 0;
}

div.su-select {
  margin: 10px 0 0 0;
  height: 40px;
  width: 100%;
}

div.su-pws {
  width: 100%;
  height: fit-content;
}

div.su-pws > button {
  margin: 0 0 0 auto;
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: var(--orange-accent);
  text-decoration: underline;
}

img.su-pay-ic {
  size: 140px;
  width: 140px;
  margin: calc(50% - 100px) auto 0 calc(50% - 70px);
  position: relative;
  z-index: 1;
}

div.su-cont > div.top.bl.pay, 
div.su-cont > div.in.pay {
  position: relative;
  z-index: 1;
}

img.su-pay-bg {
  width: 100%;
  max-width: 500px;
  object-fit: cover;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 0;
}

div.su-cont > div.in.book { height: calc(100% - 215px); }
div.su-cont > div.in > div.di.mb0.book { height: 100% }


/* Login Page */

div.login-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  display: flex;
  text-align: center;
  align-items: center;
}

div.login-d {
  width: 90%;
  max-width: 500px;
  height: fit-content;
  margin: auto auto auto auto;
  display: inline-block;
}

div.login-d > h1 {
  font-size: 25px;
  font-weight: 600;
  margin: -50px 0 35px 0;
  text-align: left;
}

div.login-d > input {
  width: calc(100% - 30px);
  padding: 15px;
  margin: 0 0 20px 0;
  height: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
  text-align: left;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.login-d > button {
  height: 50px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background-color: var(--orange-accent);
  border: none;
  border-radius: 5px;
  width: 100%;
}


/* Listings Dashboard */

div.dash-cont {
  padding: 35px 70px 70px 70px;
  min-height: calc(100% - 265px);
  height: auto;
  background-color: var(--grey-1);
  z-index: 1;
}
div.dash-cont.nav { min-height: calc(100% - 241px); }
div.dash-cont.step { min-height: calc(100% - 257px); }

div.lst-dsh-tools {
  width: 100%;
  margin-top: 20px;
}

div.lst-dsh-tools > input, div.lst-dsh-tools > label > input {
  /* width: calc(50% - 30px); */
  flex: 1;
  margin-right: 20px;
  padding: 0px 15px 0px 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}
div.lst-dsh-tools > label {
  display: flex;
  position: relative;
  flex: 1 1;
}
div.lst-dsh-tools > label > img {
  position: absolute;
  left: 13px;
  top: 14px;
  bottom: 0;
  width: 13px;
}
div.lst-dsh-tools > label > input { padding-left: 35px; }

div.lst-dsh-tools > div {
  width: calc(25% - 10px);
  margin-right: 20px;
}
div.lst-dsh-tools > div:last-child { margin-right: 0px; }
div.lst-dsh-tools > div > div:first-of-type { height: 40px; }

.css-tavquq-control { 
  border-color: var(--grey-3) !important;
}

.css-1jqq78o-placeholder {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}

div.lst-listing-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
}

div.lst-listing-card {
  padding: 20px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

div.lst-listing-card > img, div.lst-listing-card > div.placeholder {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin: 0 15px 0 0;
  background-color: var(--grey-2);
  object-fit: cover;
  object-position: center center;
}

div.lst-listing-card > div.r {
  margin: 3px 0 0 0;
}

div.lst-listing-card > div.r > h2 {
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin: 0 0 5px 0;
}

div.lst-listing-card > div.r > div > img {
  margin: 0 5px 0 0;
}

div.lst-listing-card > div.r > div > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 0;
}

.css-166bipr-Input {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
}


/* Create New Listing */

div.lst-new-cont {
  padding: 70px;
  min-height: calc(100% - 140px);
  height: auto;
  z-index: 1;
}

div.lst-new-i-d {
  width: 100%;
  margin: 0 0 30px 0;
}
div.lst-new-i-d:last-of-type { margin: 0; }
div.lst-new-i-d.mt30 { margin-top: 30px; }
div.lst-new-i-d.mb0 { margin-bottom: 0; padding-bottom: 0;}
div.lst-new-i-d.mng-si.mb0 { margin-bottom: 0; padding-bottom: 0;}

div.lst-new-i-d > h2 {
  font-size: 15px;
  margin: 0 0 15px 0;
  font-weight: 600;
}
div.lst-new-i-d > h2.mb25 { margin-bottom: 25px; }

div.lst-new-i-d > div {
  width: 100%;
  margin: 0 0 15px 0;
}

div.lst-new-i-d > div:last-child { margin-bottom: 0px; }

div.lst-new-i-d > div > input, div.lst-new-i-d > div > label > input {
  height: 40px;
  margin-right: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;

  /* width: calc(100% - 30px); */
  flex: 1;
  padding: 0 15px 0 15px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

div.lst-new-i-d > div > input:last-child, div.lst-new-i-d > div > label > input:last-child {
  margin-right: 0px;
}

input.half {
  width: calc(50% - 15px);
}
div.lst-new-i-d > div > input.half {
  width: calc(50% - 40px);
}

input.qrtr {
  width: calc(25% - 18px);
}
div.lst-new-i-d > div > input.qrtr {
  width: calc(25% - 43.5px);
}

div.lst-new-rvw-dsn-c {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: none;
  margin: 0 15px 0 0;
}
div.lst-new-rvw-dsn-c.fg0 { background-color: black; }
div.lst-new-rvw-dsn-c.fg1 { background-color: #6D7986; }
div.lst-new-rvw-dsn-c.fg2 { background-color: #033459; }
div.lst-new-rvw-dsn-c.fg3 { background-color: #3F70DE; }
div.lst-new-rvw-dsn-c.fg4 { background-color: #890539; }
div.lst-new-rvw-dsn-c.fg5 { background-color: #C33641; }
div.lst-new-rvw-dsn-c.fg6 { background-color: #EB541C; }
div.lst-new-rvw-dsn-c.fg7 { background-color: #BEB185; }
div.lst-new-rvw-dsn-c.fg8 { background-color: #B7C096; }
div.lst-new-rvw-dsn-c.fg9 { background-color: #205A2B; }
div.lst-new-rvw-dsn-c.fg10 { background-color: #1EBEA6; }
div.lst-new-rvw-dsn-c.fg11 { background-color: #815A88; }
div.lst-new-rvw-dsn-c.bg0 { outline: 1px solid var(--grey-3); background-color: white; }
div.lst-new-rvw-dsn-c.bg1 { background-color: var(--si-dark-bg); }
h3.lst-new-rvw-dsn-h {
  margin: auto auto auto 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.lst-new-rvw-dtl-d {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 30px 0;
}

div.lst-new-rvw-dtl-d > h1 {
  margin: 0;
  font-size: 20px; 
}

div.lst-new-rvw-dtl-d > div.dtl {
  margin: 8px auto 0 0;
  width: fit-content;
}

div.lst-new-rvw-dtl-d > div > img {
  margin: 0 10px 0 0;
}

div.lst-new-rvw-dtl-d > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-6);
}

div.lst-q-t-d {
  display: inline-flex;
  width: 100%;
  height: fit-content;
  margin: 0 0 15px 0;
}

div.lst-q-t-d > h2 {
  margin: auto auto auto 0;
  font-weight: 600;
  font-size: 15px;
}

div.lst-q-t-d > button {
  margin: 0 0 0 auto !important;
  background-color: var(--purple-accent) !important;
  height: 40px;
}

div.empty-q-d {
  height: 100px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: var(--grey-2);
  margin: 0;
  padding: 0;
  cursor: default;
}


/* Manage Listing */

div.lst-mng-cont {
  min-height: 100%;
  height: auto;
  background-color: var(--grey-1);
  z-index: 1;
}

div.lst-mng-top-cont {
  background-color: white;
  border-bottom: 1px solid var(--grey-3);
  display: block;
}

div.lst-mng-top-info {
  margin: 45px 0 0 70px;
  width: 80%;
  margin-bottom: 30px;
}

div.lst-mng-top-info > img, div.lst-mng-top-info > div.placeholder {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  object-fit: cover;
  background-color: var(--grey-2);
}

div.lst-mng-t-d {
  display: block;
  margin: auto auto auto 20px;
}

div.lst-mng-place-d {
  margin-top: 3px;
}

div.lst-mng-place-d > h3 {
  font-size: 15px;
  font-weight: 500;
  color: var(--grey-5);
  margin: 0 0 0 5px;
}

div.lst-mng-top-nav {
  margin-left: 61px;
}

div.lst-mng-top-nav > button {
  width: auto;
  border: none;
  background-color: transparent;
  margin: 0 30px 0 0;
  padding: 0;
}

div.lst-mng-top-nav > button:last-child {
  margin-right: 0;
}

div.lst-mng-top-nav > button > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto 12px auto;
}

div.lst-mng-top-nav > button > div {
  height: 3px;
  width: 65px;
  background-color: transparent;
  margin: 0 auto 0 auto;
}

div.lst-mng-top-nav > button > div.a {
  background-color: var(--orange-accent);
}

div.lst-mng-content {
  padding: 35px 70px 70px 70px;
  width: calc(100% - 140px);
}

div.lst-mng-chng-cont {
  position: fixed;
  z-index: 5;
  width: calc(100% - 177px);
  left: 120px;
  bottom: 70px;
  height: 70px;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  box-shadow: 0px 0px 15px var(--grey-7);
}

div.lst-mng-chng-cont > button {
  height: 40px;
  width: 150px;
  margin: auto 15px auto 0;

  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: var(--orange-accent);
}

div.lst-mng-chng-cont > button.cancel {
  background-color: transparent;
  color: black;
  margin-right: 15px;

  border: 1px solid black;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

div.lst-mng-chng-cont > h3 {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin: auto auto auto 15px;
}


/* Manage Listing - Sign In */

div.lst-new-i-d.mng-si {
  padding-bottom: 80px;
}

div.lst-new-i-d.mng-si > div:first-of-type > button {
  width: 150px;
  border: none;
  background-color: black;
  color: white;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 0 0 15px;
}
div.lst-new-i-d.mng-si > div:first-of-type > button.reset {
  margin-left: auto;
  width: 100px;
  background-color: var(--purple-accent-lite);
  color: var(--purple-accent);

  border: 1px solid var(--purple-accent);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
div.lst-new-i-d.mng-si > div:first-of-type > button.save {
  background-color: var(--purple-accent)
}

div.lst-new-i-d.mng-si > div:first-of-type > div {
  width: 350px;
  margin: 0;
}

div.lst-new-i-d.mng-si > div:first-of-type > button > img {
  margin: 0 8px 0 0;
}

button.lst-mng-si-launch {
  width: 100px;
  border: none;
  background-color: var(--orange-accent);
  color: white;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  height: 38px;
}

div.lst-mng-si-link {
  width: calc(100% - 115px);
  margin-right: 15px;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.lst-mng-si-link > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: auto auto auto 10px;
  width: fit-content;
  color: var(--grey-5);
}

div.lst-mng-si-link > h3 > span {
  color: black;
  font-weight: 500;
}

div.lst-mng-si-q-cont {
  width: 100%;
  height: fit-content;
}

div.lst-mng-si-q-cont > div {
  width: calc(100% - 32px);
  height: 20px;
  padding: 15px;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  margin: 0 0 15px 0;
  opacity: 0.999; 
  cursor: pointer;
}
div.lst-mng-si-q-cont > div:last-child { margin-bottom: 0; }
div.lst-mng-si-q-cont > div.dragged { border: 2px solid var(--purple-accent); }
div.lst-mng-si-q-cont > div.dragbar {
  width: 100%;
  height: 3px;
  padding: 0;
  background-color: var(--purple-accent);
  border: none;
}

div.lst-mng-si-q-cont > div > div:first-child {
  height: fit-content;
  width: fit-content;
  margin: auto auto auto 0;
}

div.lst-mng-si-q-cont > div > div:first-child > img {
  margin: auto 15px auto 0;
}

div.lst-mng-si-q-cont > div > div:first-child > h2 {
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: black;
}

div.lst-mng-si-q-cont > div > div.q-type {
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto auto;
}

div.lst-mng-si-q-cont > div > div.q-type > img {
  margin: auto 8px auto 0;
}

div.lst-mng-si-q-cont > div > div.q-type > h3 {
  font-weight: 400;
  font-size: 15px;
  color: var(--grey-4);
  margin: 0;
}

div.cat-in {
  width: 100%;
  max-width: 515px;
}

h3.lst-mng-hs {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.lst-mng-toggle {
  height: 20px;
  width: 40px;
  background-color: var(--orange-accent);
  border-radius: 20px;
  margin: 0 auto 0 15px;
  cursor: pointer;
}
div.lst-mng-toggle.off { background-color: var(--grey-3); }
div.lst-mng-toggle > div {
  background-color: white;
  border: none;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin: auto auto auto 5px;
  transition: 0.1s ease-out;
}
div.lst-mng-toggle.off > div { background-color: var(--grey-7); margin: auto 5px auto auto; }


/* Manage Listing - Leads */

label.lst-mng-leads-search {
  width: calc(100% - 230px);
  margin-right: 15px;
  display: flex;
  position: relative;
}
label.lst-mng-leads-search > img {
  position: absolute;
  left: 13px;
  top: 14px;
  bottom: 0;
  width: 13px;
}
div.lst-new-i-d > div > label.lst-mng-leads-search > input { 
  width: 100%;
  padding-left: 35px;
}

button.lst-mng-leads-b {
  width: 100px;
  border: none;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  height: 42px;
  margin-right: 15px;
}

button.lst-mng-leads-b:last-child {
  margin-right: 0px;
}

button.lst-mng-leads-b.sort {
  background-color: transparent;
  color: black;

  border: 1px solid black;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

button.lst-mng-leads-b.filter {
  background-color: black;
  color: white;
}

button.lst-mng-leads-b.export {
  background-color: green;
  color: white;
}

button.lst-mng-leads-b > img {
  margin: 0 8px 0 0;
}

button.lst-mng-leads-card {
  width: 100%;
  height: 50px;
  border: 1px solid var(--grey-3);
  background-color: white;
  border-radius: 5px;
  margin: 0 0 15px 0;
  display: flex;
}

button.lst-mng-leads-card:last-child {
  margin: 0;
}

button.lst-mng-leads-card > h2 {
  margin: auto 0 auto 20px;
  font-size: 15px;
  font-weight: 400;
}

button.lst-mng-leads-card > div {
  margin: auto 20px auto auto;
}

button.lst-mng-leads-card > div > img {
  margin-right: 5px;
}

button.lst-mng-leads-card > div > h3 {
  font-size: 15px;
  color: var(--grey-4);
  margin: 0;
  font-weight: 400;
}


/* Manage Listing - Lead Profile */

div.lst-mng-cont.white {
  background-color: white;
}

button.lead-prof-back-b {
  height: 45px;
  width: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  color: white;
}

div.lead-prof-top > div.r {
  margin: auto 0 auto 20px;
}

button.lead-prof-back-b > img {
  margin-top: 2px;
}

div.lead-prof-pf {
  height: 55px;
  width: 55px;
  margin: 0 auto 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  text-align: center;
}

div.lead-prof-pf > h1 {
  margin: 18px auto 0 auto;
  color: white;
  font-size: 22px;
}

h1.lead-prof-nm {
  font-size: 20px;
  font-weight: 500;
  font-family: 'NBIP';
  color: black;
  margin: 30px 0 0 0;
}
h1.lead-prof-nm.lp { margin-top: 0; }

div.lead-prof-place {
  margin: 5px 0 0 0;
}

div.lead-prof-place > img {
  margin: 0 8px 0 0;
}

div.lead-prof-place > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 0;
}

div.lst-new-i-d.lead-prof {
  margin: 20px 0 20px 0;
}

div.lead-prof-contact-d {
  border: 1px solid var(--grey-3);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border-radius: 5px;
  width: calc(50% - 10px);
  margin: 0 20px 0 0;
  height: 50px;
  background-color: white;
}

div.lead-prof-contact-d:last-child {
  margin: 0;
}

div.lead-prof-contact-d > img {
  width: 15px;
  margin-left: 20px;
  margin-right: 15px;
}

div.lead-prof-contact-d > h3 {
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin: auto auto auto 0;
}

h3.lead-prof-notes {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: auto auto auto 0;
}

div.lst-new-i-d > div.lead-prof-si-ans-d {
  border-radius: 5px;
  width: calc(100% - 30px);
  border: 1px solid var(--grey-3);
  padding: 15px;
  background-color: white;
}

div.lst-new-i-d > div.lead-prof-si-ans-d.yn {
  padding-bottom: 10px;
}

div.lead-prof-si-ans-d > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 0;
}

div.lead-prof-si-ans-d > div {
  margin: 5px 0 0 0;
}

div.lead-prof-si-ans-d > div > h3 {
  margin: 2px 0 0 0;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

div.lead-prof-si-ans-d > div > img {
  margin: 0 8px -2px 0;
}

div.lead-prof-b-d {
  padding: 20px;
  height: 40px;
  width: calc(100% - 40px) !important;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
}

div.lead-prof-b-d > div {
  height: fit-content;
  margin: auto auto auto 0;
}

div.lead-prof-b-d > div > h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

div.lead-prof-b-d > div > h3 {
  margin: 2px 0 0 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.lead-prof-b-d > button {
  height: 40px;
  padding: 0 15px 0 15px;
  margin: auto 0 auto auto;
  border: 1px solid var(--delete-fg);
  border-radius: 5px;
  background-color: var(--delete-bg-fade);
  color: var(--delete-fg);
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter', sans-serif; 
  transition: 0.2s ease-in-out;
}

div.lead-prof-b-d > button:hover {
  transform: scale(1.05);
}


/* Manage Listing - Design Sign In */

div.mng-lst-dsn-cont {
  width: 100%;
  margin: 0;
}

div.mng-lst-dsn-split-d {
  width: 50%;
}
div.mng-lst-dsn-split-d:first-of-type {
  width: 40%;
  margin: 0 auto 0 0;
}
div.mng-lst-dsn-split-d:last-of-type {
  margin: 0 0 0 auto;
}

div.mng-lst-dsn-split-d > h2, div.mng-lst-dsn-split-d > div > h2 {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color: black;
}

div.mng-lst-dsn-colors-d {
  display: inline-table;
  margin: 0 0 15px -15px;
}

div.mng-lst-dsn-colors-d > button {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: none;
  margin: 0 0 15px 15px;
}

div.mng-lst-dsn-colors-d > button.fg0 { background-color: black; }
div.mng-lst-dsn-colors-d > button.fg1 { background-color: #6D7986; }
div.mng-lst-dsn-colors-d > button.fg2 { background-color: #033459; }
div.mng-lst-dsn-colors-d > button.fg3 { background-color: #3F70DE; }
div.mng-lst-dsn-colors-d > button.fg4 { background-color: #890539; }
div.mng-lst-dsn-colors-d > button.fg5 { background-color: #C33641; }
div.mng-lst-dsn-colors-d > button.fg6 { background-color: #EB541C; }
div.mng-lst-dsn-colors-d > button.fg7 { background-color: #BEB185; }
div.mng-lst-dsn-colors-d > button.fg8 { background-color: #B7C096; }
div.mng-lst-dsn-colors-d > button.fg9 { background-color: #205A2B; }
div.mng-lst-dsn-colors-d > button.fg10 { background-color: #1EBEA6; }
div.mng-lst-dsn-colors-d > button.fg11 { background-color: #815A88; }

div.mng-lst-dsn-colors-d > button.bg0 { outline: 1px solid var(--grey-3); background-color: white; }
div.mng-lst-dsn-colors-d > button.bg1 { background-color: var(--si-dark-bg); }

div.mng-lst-dsn-colors-d > button > img.hide {
  display: none;
}

div.mng-lst-dsn-colors-d > button > img {
  margin: auto;
  height: 48px;
  object-fit: none;
  width: 30px;
}
/* Dark Checkmarks */
div.mng-lst-dsn-colors-d > button.bg0 > img { filter: invert(1); }

div.mng-lst-dsn-preview-cont {
  width: 100%;
  height: auto;
  border: none;
  border-radius: 5px;
  display: block;
  text-align: center;
}

div.mng-lst-dsn-preview-cont.bg0 { outline: 1px solid var(--grey-3); background-color: white; }
div.mng-lst-dsn-preview-cont.bg1 { background-color: var(--si-dark-bg); }

div.mng-lst-dsn-preview-prog {
  margin: 40px auto 0 auto;
}

div.mng-lst-dsn-preview-prog > div {
  border: 1px solid var(--grey-5);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  margin: 0 5px 0 0;
  border-radius: 20px;
  width: 12px;
  height: 12px;
}
div.mng-lst-dsn-preview-prog > div:last-child { margin: 0; }
div.mng-lst-dsn-preview-prog > div.filled { border: none; }

div.mng-lst-dsn-preview-prog > div.filled.fg0 { background-color: black; }
div.mng-lst-dsn-preview-prog > div.fg0 { border-color: #00000070; }
div.mng-lst-dsn-preview-prog > div.filled.fg1 { background-color: #6D7986; }
div.mng-lst-dsn-preview-prog > div.fg1 { border-color: #6D798670; }
div.mng-lst-dsn-preview-prog > div.filled.fg2 { background-color: #033459; }
div.mng-lst-dsn-preview-prog > div.fg2 { border-color: #03345970; }
div.mng-lst-dsn-preview-prog > div.filled.fg3 { background-color: #3F70DE; }
div.mng-lst-dsn-preview-prog > div.fg3 { border-color: #3F70DE70; }
div.mng-lst-dsn-preview-prog > div.filled.fg4 { background-color: #890539; }
div.mng-lst-dsn-preview-prog > div.fg4 { border-color: #89053970; }
div.mng-lst-dsn-preview-prog > div.filled.fg5 { background-color: #C33641; }
div.mng-lst-dsn-preview-prog > div.fg5 { border-color: #C3364170; }
div.mng-lst-dsn-preview-prog > div.filled.fg6 { background-color: #EB541C; }
div.mng-lst-dsn-preview-prog > div.fg6 { border-color: #EB541C70; }
div.mng-lst-dsn-preview-prog > div.filled.fg7 { background-color: #BEB185; }
div.mng-lst-dsn-preview-prog > div.fg7 { border-color: #BEB18570; }
div.mng-lst-dsn-preview-prog > div.filled.fg8 { background-color: #B7C096; }
div.mng-lst-dsn-preview-prog > div.fg8 { border-color: #B7C09670; }
div.mng-lst-dsn-preview-prog > div.filled.fg9 { background-color: #205A2B; }
div.mng-lst-dsn-preview-prog > div.fg9 { border-color: #205A2B70; }
div.mng-lst-dsn-preview-prog > div.filled.fg10 { background-color: #1EBEA6; }
div.mng-lst-dsn-preview-prog > div.fg10 { border-color: #1EBEA670; }
div.mng-lst-dsn-preview-prog > div.filled.fg11 { background-color: #815A88; }
div.mng-lst-dsn-preview-prog > div.fg11 { border-color: #815A8870; }


div.mng-lst-dsn-preview-cont > h1 {
  font-size: 25px;
  margin: 25px 0 0 0;
}
div.mng-lst-dsn-preview-cont > h1.bg0 { color: black; }
div.mng-lst-dsn-preview-cont > h1.bg1 { color: white; }

div.mng-lst-dsn-preview-q {
  margin: 35px 0 0 0;
  width: calc(100% - 80px);
  margin-left: 40px;
  text-align: left;
}

div.mng-lst-dsn-preview-q > h2 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
div.mng-lst-dsn-preview-q > h2.bg0 { color: black; }
div.mng-lst-dsn-preview-q > h2.bg1 { color: white; }

div.mng-lst-dsn-preview-q > div {
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  width: calc(100% - 30px);
  margin: 15px 0 0 0;
}
div.mng-lst-dsn-preview-q > div.fg0 { border-color: #00000070; }
div.mng-lst-dsn-preview-q > div.fg1 { border-color: #6D798670; }
div.mng-lst-dsn-preview-q > div.fg2 { border-color: #03345970; }
div.mng-lst-dsn-preview-q > div.fg3 { border-color: #3F70DE70; }
div.mng-lst-dsn-preview-q > div.fg4 { border-color: #89053970; }
div.mng-lst-dsn-preview-q > div.fg5 { border-color: #C3364170; }
div.mng-lst-dsn-preview-q > div.fg6 { border-color: #EB541C70; }
div.mng-lst-dsn-preview-q > div.fg7 { border-color: #BEB18570; }
div.mng-lst-dsn-preview-q > div.fg8 { border-color: #B7C09670; }
div.mng-lst-dsn-preview-q > div.fg9 { border-color: #205A2B70; }
div.mng-lst-dsn-preview-q > div.fg10 { border-color: #1EBEA670; }
div.mng-lst-dsn-preview-q > div.fg11 { border-color: #815A8870; }

div.mng-lst-dsn-preview-q > div > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

div.mng-lst-dsn-preview-q > div > h3.fg0 { color: black; }
div.mng-lst-dsn-preview-q > div > h3.fg1 { color: #4b545d; }
div.mng-lst-dsn-preview-q > div > h3.fg2 { color: #012038; }
div.mng-lst-dsn-preview-q > div > h3.fg3 { color: #3054a5; }
div.mng-lst-dsn-preview-q > div > h3.fg4 { color: #5a0526; }
div.mng-lst-dsn-preview-q > div > h3.fg5 { color: #8b272f; }
div.mng-lst-dsn-preview-q > div > h3.fg6 { color: #b43c10; }
div.mng-lst-dsn-preview-q > div > h3.fg7 { color: #9a8e65; }
div.mng-lst-dsn-preview-q > div > h3.fg8 { color: #767c61; }
div.mng-lst-dsn-preview-q > div > h3.fg9 { color: #153b1c; }
div.mng-lst-dsn-preview-q > div > h3.fg10 { color: #13796a; }
div.mng-lst-dsn-preview-q > div > h3.fg11 { color: #523957; }


div.mng-lst-dsn-preview-yes {
  outline: 1px solid black;
  width: 17px;
  height: 17px;
  border-radius: 20px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
}
div.mng-lst-dsn-preview-yes.fg0 { outline-color: black; }
div.mng-lst-dsn-preview-yes.fg1 { outline-color: #4b545d; }
div.mng-lst-dsn-preview-yes.fg2 { outline-color: #012038; }
div.mng-lst-dsn-preview-yes.fg3 { outline-color: #3054a5; }
div.mng-lst-dsn-preview-yes.fg4 { outline-color: #5a0526; }
div.mng-lst-dsn-preview-yes.fg5 { outline-color: #8b272f; }
div.mng-lst-dsn-preview-yes.fg6 { outline-color: #b43c10; }
div.mng-lst-dsn-preview-yes.fg7 { outline-color: #9a8e65; }
div.mng-lst-dsn-preview-yes.fg8 { outline-color: #767c61; }
div.mng-lst-dsn-preview-yes.fg9 { outline-color: #153b1c; }
div.mng-lst-dsn-preview-yes.fg10 { outline-color: #13796a; }
div.mng-lst-dsn-preview-yes.fg11 { outline-color: #523957; }

div.mng-lst-dsn-preview-yes > div {
  border-radius: 20px;
  height: 9px;
  width: 9px;
  background-color: black;
  margin: 4px;
}
div.mng-lst-dsn-preview-yes.fg0 > div { background-color: black; }
div.mng-lst-dsn-preview-yes.fg1 > div { background-color: #4b545d; }
div.mng-lst-dsn-preview-yes.fg2 > div { background-color: #012038; }
div.mng-lst-dsn-preview-yes.fg3 > div { background-color: #3054a5; }
div.mng-lst-dsn-preview-yes.fg4 > div { background-color: #5a0526; }
div.mng-lst-dsn-preview-yes.fg5 > div { background-color: #8b272f; }
div.mng-lst-dsn-preview-yes.fg6 > div { background-color: #b43c10; }
div.mng-lst-dsn-preview-yes.fg7 > div { background-color: #9a8e65; }
div.mng-lst-dsn-preview-yes.fg8 > div { background-color: #767c61; }
div.mng-lst-dsn-preview-yes.fg9 > div { background-color: #153b1c; }
div.mng-lst-dsn-preview-yes.fg10 > div { background-color: #13796a; }
div.mng-lst-dsn-preview-yes.fg11 > div { background-color: #523957; }

div.mng-lst-dsn-preview-q > button {
  width: 100%;
  height: 50px;
  border: none;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  margin: 25px 0 40px 0;
  color: white;
}
div.mng-lst-dsn-preview-q > button.fg0 { background-color: black; }
div.mng-lst-dsn-preview-q > button.fg1 { background-color: #6D7986; }
div.mng-lst-dsn-preview-q > button.fg2 { background-color: #033459; }
div.mng-lst-dsn-preview-q > button.fg3 { background-color: #3F70DE; }
div.mng-lst-dsn-preview-q > button.fg4 { background-color: #890539; }
div.mng-lst-dsn-preview-q > button.fg5 { background-color: #C33641; }
div.mng-lst-dsn-preview-q > button.fg6 { background-color: #EB541C; }
div.mng-lst-dsn-preview-q > button.fg7 { background-color: #BEB185; }
div.mng-lst-dsn-preview-q > button.fg8 { background-color: #B7C096; }
div.mng-lst-dsn-preview-q > button.fg9 { background-color: #205A2B; }
div.mng-lst-dsn-preview-q > button.fg10 { background-color: #1EBEA6; }
div.mng-lst-dsn-preview-q > button.fg11 { background-color: #815A88; }


/* Sign In Page */

div.visit-start-cont {
  text-align: center;
}

div.visit-start-cont > div.placeholder, div.visit-side-img > div.placeholder {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 2;
}
div.visit-start-cont > div.placeholder { right: 0; }
div.visit-side-img > div.placeholder { width: calc(100% - 500px); }

div.visit-start-info {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 3;
  bottom: 55px;
  left: auto;
  right: auto;
}

div.visit-start-info > h3 {
  margin: 0 0 3px 0;
  color: var(--grey-7);
  font-family: serif;
  font-size: 17px;
  font-weight: 500;
}

div.visit-start-info > h1 {
  font-family: serif;
  font-size: 30px;
  color: white;
  margin: 0 0 15px 0;
}

div.visit-start-info > button {
  width: 150px;
  height: 50px;
  margin: 0;
  border: 1px solid var(--grey-6);
  border-radius: 5px;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: white;
}

div.visit-start-bg {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    position: fixed;
    top: 0; bottom: 0; right: 0; left: 0;
    z-index: 2;
}

img.visit-start-img {

  object-fit: cover;
  background-color: black;
  transition: opacity 1s;
}

div.CrossfadeImage {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important; bottom: 0 !important; right: 0 !important; left: 0 !important;
  z-index: 1 !important;
}

div.CrossfadeImage > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

div.visit-in-cont {
  top: 0; bottom: 0; left: 0; right: 0;
}

div.visit-side-img > div.CrossfadeImage {
  max-width: 50% !important;
}

div.visit-side-img > div.CrossfadeImage > img {
  position: fixed !important;
  width: calc(100% - 500px) !important;
  height: 100% !important;
  object-fit: cover !important;
  margin: 0 !important;
}

div.visit-q-cont {
  position: fixed;
  width: 50%;
  max-width: 500px;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background-color: var(--si-light-bg);
}
div.visit-q-cont.dark {
  background-color: var(--si-dark-bg);
}

div.visit-q-cont > button {
  position: absolute;
  width: calc(100% - 80px);
  margin-left: 40px;
  bottom: 40px;
  background-color: black;
  color: white;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  height: 50px;
  border: none;
  border-radius: 5px;
}
div.visit-q-cont > button.finished {
  position: relative;
  margin-top: 100px;
}

div.visit-q-cont.black > button { background-color: var(--si-fg-black-main) }
div.visit-q-cont.silver > button { background-color: var(--si-fg-silver-main) }
div.visit-q-cont.navy > button { background-color: var(--si-fg-navy-main) }
div.visit-q-cont.blue > button { background-color: var(--si-fg-blue-main) }
div.visit-q-cont.maroon > button { background-color: var(--si-fg-maroon-main) }
div.visit-q-cont.red > button { background-color: var(--si-fg-red-main) }
div.visit-q-cont.orange > button { background-color: var(--si-fg-orange-main) }
div.visit-q-cont.gold > button { background-color: var(--si-fg-gold-main) }
div.visit-q-cont.lime > button { background-color: var(--si-fg-lime-main) }
div.visit-q-cont.green > button { background-color: var(--si-fg-green-main) }
div.visit-q-cont.turquoise > button { background-color: var(--si-fg-aqua-main) }
div.visit-q-cont.purple > button { background-color: var(--si-fg-purple-main) }

div.visit-q-cont > h1 {
  font-size: 25px;
  margin: 35px auto 60px auto;
  text-align: center;
  width: 65%;
  line-height: 1.2;
  color: black;
}
div.visit-q-cont.dark > h1 { color: var(--si-light-bg); }

div.visit-prog-cont {
  text-align: center;
  margin: 40px 0 0 0;
}

div.visit-prog-d {
  width: fit-content;
  margin: 0 auto 0 auto;
}

div.visit-prog-d > div {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--grey-4);
  height: 12px;
  width: 12px;
  border-radius: 10px;
  margin-right: 5px;
}
div.visit-prog-d > div:last-child { margin-right: 0px; }
div.visit-prog-d > div.filled { border: none; }

div.visit-q-cont.black div.visit-prog-d > div { border: 1px solid var(--si-fg-black-fade) }
div.visit-q-cont.silver div.visit-prog-d > div { border: 1px solid var(--si-fg-silver-fade) }
div.visit-q-cont.navy div.visit-prog-d > div { border: 1px solid var(--si-fg-navy-fade) }
div.visit-q-cont.blue div.visit-prog-d > div { border: 1px solid var(--si-fg-blue-fade) }
div.visit-q-cont.maroon div.visit-prog-d > div { border: 1px solid var(--si-fg-maroon-fade) }
div.visit-q-cont.red div.visit-prog-d > div { border: 1px solid var(--si-fg-red-fade) }
div.visit-q-cont.orange div.visit-prog-d > div { border: 1px solid var(--si-fg-orange-fade) }
div.visit-q-cont.gold div.visit-prog-d > div { border: 1px solid var(--si-fg-gold-fade) }
div.visit-q-cont.lime div.visit-prog-d > div { border: 1px solid var(--si-fg-lime-fade) }
div.visit-q-cont.green div.visit-prog-d > div { border: 1px solid var(--si-fg-green-fade) }
div.visit-q-cont.turquoise div.visit-prog-d > div { border: 1px solid var(--si-fg-aqua-fade) }
div.visit-q-cont.purple div.visit-prog-d > div { border: 1px solid var(--si-fg-purple-fade) }

div.visit-q-cont.black div.visit-prog-d > div.filled { background-color: var(--si-fg-black-main) }
div.visit-q-cont.silver div.visit-prog-d > div.filled { background-color: var(--si-fg-silver-main) }
div.visit-q-cont.navy div.visit-prog-d > div.filled { background-color: var(--si-fg-navy-main) }
div.visit-q-cont.blue div.visit-prog-d > div.filled { background-color: var(--si-fg-blue-main) }
div.visit-q-cont.maroon div.visit-prog-d > div.filled { background-color: var(--si-fg-maroon-main) }
div.visit-q-cont.red div.visit-prog-d > div.filled { background-color: var(--si-fg-red-main) }
div.visit-q-cont.orange div.visit-prog-d > div.filled { background-color: var(--si-fg-orange-main) }
div.visit-q-cont.gold div.visit-prog-d > div.filled { background-color: var(--si-fg-gold-main) }
div.visit-q-cont.lime div.visit-prog-d > div.filled { background-color: var(--si-fg-lime-main) }
div.visit-q-cont.green div.visit-prog-d > div.filled { background-color: var(--si-fg-green-main) }
div.visit-q-cont.turquoise div.visit-prog-d > div.filled { background-color: var(--si-fg-aqua-main) }
div.visit-q-cont.purple div.visit-prog-d > div.filled { background-color: var(--si-fg-purple-main) }

div.visit-q-d {
  width: calc(100% - 80px);
  margin: 0 40px 0 40px;
}

div.visit-q-d > div {
  margin: 0 0 30px 0;
  width: 100%;
}

div.visit-q-d > div > h2 {
  font-size: 15px;
  font-weight: 600;
  color: black;
  margin: 0 0 15px 0;
}
div.visit-q-cont.dark > div.visit-q-d > div > h2 { color: var(--si-light-bg) }

div.visit-q-d > div > h2 > span {
  font-weight: 400;
  color: var(--grey-4);
}
div.visit-q-cont.dark > div.visit-q-d > div > h2 > span { color: #ffffff50; }

div.visit-q-d > div > input {
  width: calc(100% - 30px);
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  color: black;
  background-color: transparent;
}
div.visit-q-cont.dark div.visit-q-d > div > input { color: var(--si-light-bg); }

div.visit-q-cont.black div.visit-q-d > div > input { border: 1px solid var(--si-fg-black-fade); }
div.visit-q-cont.silver div.visit-q-d > div > input { border: 1px solid var(--si-fg-silver-fade); }
div.visit-q-cont.navy div.visit-q-d > div > input { border: 1px solid var(--si-fg-navy-fade); }
div.visit-q-cont.blue div.visit-q-d > div > input { border: 1px solid var(--si-fg-blue-fade); }
div.visit-q-cont.maroon div.visit-q-d > div > input { border: 1px solid var(--si-fg-maroon-fade); }
div.visit-q-cont.red div.visit-q-d > div > input { border: 1px solid var(--si-fg-red-fade); }
div.visit-q-cont.gold div.visit-q-d > div > input { border: 1px solid var(--si-fg-gold-fade); }
div.visit-q-cont.lime div.visit-q-d > div > input { border: 1px solid var(--si-fg-lime-fade); }
div.visit-q-cont.green div.visit-q-d > div > input { border: 1px solid var(--si-fg-green-fade); }
div.visit-q-cont.turquoise div.visit-q-d > div > input { border: 1px solid var(--si-fg-aqua-fade); }
div.visit-q-cont.purple div.visit-q-d > div > input { border: 1px solid var(--si-fg-purple-fade); }
div.visit-q-cont.black div.visit-q-d > div > input:focus { outline-color: var(--si-fg-black-main); }
div.visit-q-cont.silver div.visit-q-d > div > input:focus { outline-color: var(--si-fg-silver-main); }
div.visit-q-cont.navy div.visit-q-d > div > input:focus { outline-color: var(--si-fg-navy-main); }
div.visit-q-cont.blue div.visit-q-d > div > input:focus { outline-color: var(--si-fg-blue-main); }
div.visit-q-cont.maroon div.visit-q-d > div > input:focus { outline-color: var(--si-fg-maroon-main); }
div.visit-q-cont.red div.visit-q-d > div > input:focus { outline-color: var(--si-fg-red-main); }
div.visit-q-cont.orange div.visit-q-d > div > input:focus { outline-color: var(--si-fg-orange-main); }
div.visit-q-cont.gold div.visit-q-d > div > input:focus { outline-color: var(--si-fg-gold-main); }
div.visit-q-cont.lime div.visit-q-d > div > input:focus { outline-color: var(--si-fg-lime-main); }
div.visit-q-cont.green div.visit-q-d > div > input:focus { outline-color: var(--si-fg-green-main); }
div.visit-q-cont.turquoise div.visit-q-d > div > input:focus { outline-color: var(--si-fg-aqua-main); }
div.visit-q-cont.purple div.visit-q-d > div > input:focus { outline-color: var(--si-fg-purple-main); }
div.visit-q-cont.black div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-black-main); }
div.visit-q-cont.silver div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-silver-main); }
div.visit-q-cont.navy div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-navy-main); }
div.visit-q-cont.blue div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-blue-main); }
div.visit-q-cont.maroon div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-maroon-main); }
div.visit-q-cont.red div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-red-main); }
div.visit-q-cont.orange div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-orange-main); }
div.visit-q-cont.lime div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-lime-main); }
div.visit-q-cont.green div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-green-main); }
div.visit-q-cont.turquoise div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-aqua-main); }
div.visit-q-cont.purple div.visit-q-d > div > input:focus-visible { outline-color: var(--si-fg-purple-main); }

div.visit-q-d > div > textarea {
  width: calc(100% - 30px);
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  color: black;
  height: calc(100vh - 375px);
  resize: none;
  background-color: transparent;
}
div.visit-q-cont.dark > div.visit-q-d > div > textarea { color: var(--si-light-bg); }
div.visit-q-cont.black div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-black-fade); }
div.visit-q-cont.silver div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-silver-fade); }
div.visit-q-cont.navy div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-navy-fade); }
div.visit-q-cont.blue div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-blue-fade); }
div.visit-q-cont.maroon div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-maroon-fade); }
div.visit-q-cont.red div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-red-fade); }
div.visit-q-cont.orange div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-orange-fade); }
div.visit-q-cont.gold div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-gold-fade); }
div.visit-q-cont.lime div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-lime-fade); }
div.visit-q-cont.green div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-green-fade); }
div.visit-q-cont.turquoise div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-aqua-fade); }
div.visit-q-cont.purple div.visit-q-d > div > textarea { border: 1px solid var(--si-fg-purple-fade); }
div.visit-q-cont.black div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-black-main); }
div.visit-q-cont.silver div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-silver-main); }
div.visit-q-cont.navy div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-navy-main); }
div.visit-q-cont.blue div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-blue-main); }
div.visit-q-cont.maroon div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-maroon-main); }
div.visit-q-cont.red div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-red-main); }
div.visit-q-cont.orange div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-orange-main); }
div.visit-q-cont.gold div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-gold-main); }
div.visit-q-cont.lime div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-lime-main); }
div.visit-q-cont.green div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-green-main); }
div.visit-q-cont.turquoise div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-aqua-main); }
div.visit-q-cont.purple div.visit-q-d > div > textarea:focus { outline-color: var(--si-fg-purple-main); }


div.visit-q-d > div > button {
  width: 100%;
  height: 50px;
  padding: 15px;
  text-align: left;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  color: black;
  background-color: transparent;
  margin-bottom: 10px;
  display: inline-flex;
}
div.visit-q-d > div > button:last-child { margin: 0 }
div.visit-q-d > div > button.hidden { opacity: 0.5 !important; }

div.visit-q-cont.black div.visit-q-d > div > button { color: var(--si-fg-black-secondary); border-color: var(--si-fg-black-fade); }
div.visit-q-cont.silver div.visit-q-d > div > button { color: var(--si-fg-silver-secondary); border-color: var(--si-fg-silver-fade); }
div.visit-q-cont.navy div.visit-q-d > div > button { color: var(--si-fg-navy-secondary); border-color: var(--si-fg-navy-fade); }
div.visit-q-cont.blue div.visit-q-d > div > button { color: var(--si-fg-blue-secondary); border-color: var(--si-fg-blue-fade); }
div.visit-q-cont.maroon div.visit-q-d > div > button { color: var(--si-fg-maroon-secondary); border-color: var(--si-fg-maroon-fade); }
div.visit-q-cont.red div.visit-q-d > div > button { color: var(--si-fg-red-secondary); border-color: var(--si-fg-red-fade); }
div.visit-q-cont.orange div.visit-q-d > div > button { color: var(--si-fg-orange-secondary); border-color: var(--si-fg-orange-fade); }
div.visit-q-cont.gold div.visit-q-d > div > button { color: var(--si-fg-gold-secondary); border-color: var(--si-fg-gold-fade); }
div.visit-q-cont.lime div.visit-q-d > div > button { color: var(--si-fg-lime-secondary); border-color: var(--si-fg-lime-fade); }
div.visit-q-cont.green div.visit-q-d > div > button { color: var(--si-fg-green-secondary); border-color: var(--si-fg-green-fade); }
div.visit-q-cont.turquoise div.visit-q-d > div > button { color: var(--si-fg-aqua-secondary); border-color: var(--si-fg-aqua-fade); }
div.visit-q-cont.purple div.visit-q-d > div > button { color: var(--si-fg-purple-secondary); border-color: var(--si-fg-purple-fade); }

div.visit-select-c {
  height: 17px;
  width: 17px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--grey-4);
  border-radius: 10px;
  margin-right: 10px;
}

div.visit-q-cont.black div.visit-select-c { border-color: var(--si-fg-black-fade) }
div.visit-q-cont.silver div.visit-select-c { border-color: var(--si-fg-silver-fade) }
div.visit-q-cont.navy div.visit-select-c { border-color: var(--si-fg-navy-fade) }
div.visit-q-cont.blue div.visit-select-c { border-color: var(--si-fg-blue-fade) }
div.visit-q-cont.maroon div.visit-select-c { border-color: var(--si-fg-maroon-fade) }
div.visit-q-cont.red div.visit-select-c { border-color: var(--si-fg-red-fade) }
div.visit-q-cont.orange div.visit-select-c { border-color: var(--si-fg-orange-fade) }
div.visit-q-cont.gold div.visit-select-c { border-color: var(--si-fg-gold-fade) }
div.visit-q-cont.lime div.visit-select-c { border-color: var(--si-fg-lime-fade) }
div.visit-q-cont.green div.visit-select-c { border-color: var(--si-fg-green-fade) }
div.visit-q-cont.turquoise div.visit-select-c { border-color: var(--si-fg-aqua-fade) }
div.visit-q-cont.purple div.visit-select-c { border-color: var(--si-fg-purple-fade) }
div.visit-q-cont.black button.chosen > div.visit-select-c { border-color: var(--si-fg-black-secondary) }
div.visit-q-cont.silver button.chosen > div.visit-select-c { border-color: var(--si-fg-silver-secondary) }
div.visit-q-cont.navy button.chosen > div.visit-select-c { border-color: var(--si-fg-navy-secondary) }
div.visit-q-cont.blue button.chosen > div.visit-select-c { border-color: var(--si-fg-blue-secondary) }
div.visit-q-cont.maroon button.chosen > div.visit-select-c { border-color: var(--si-fg-maroon-secondary) }
div.visit-q-cont.red button.chosen > div.visit-select-c { border-color: var(--si-fg-red-secondary) }
div.visit-q-cont.orange button.chosen > div.visit-select-c { border-color: var(--si-fg-orange-secondary) }
div.visit-q-cont.gold button.chosen > div.visit-select-c { border-color: var(--si-fg-gold-secondary) }
div.visit-q-cont.lime button.chosen > div.visit-select-c { border-color: var(--si-fg-lime-secondary) }
div.visit-q-cont.green button.chosen > div.visit-select-c { border-color: var(--si-fg-green-secondary) }
div.visit-q-cont.turquoise button.chosen > div.visit-select-c { border-color: var(--si-fg-aqua-secondary) }
div.visit-q-cont.purple button.chosen > div.visit-select-c { border-color: var(--si-fg-purple-secondary) }

div.visit-select-c > div {
  background-color: transparent;
  border-radius: 10px;
  width: 9px;
  height: 9px;
  margin: 3px;
}

div.visit-q-cont.black button.chosen > div.visit-select-c > div { background-color: var(--si-fg-black-secondary) }
div.visit-q-cont.silver button.chosen > div.visit-select-c > div { background-color: var(--si-fg-silver-secondary) }
div.visit-q-cont.navy button.chosen > div.visit-select-c > div { background-color: var(--si-fg-navy-secondary) }
div.visit-q-cont.blue button.chosen > div.visit-select-c > div { background-color: var(--si-fg-blue-secondary) }
div.visit-q-cont.maroon button.chosen > div.visit-select-c > div { background-color: var(--si-fg-maroon-secondary) }
div.visit-q-cont.red button.chosen > div.visit-select-c > div { background-color: var(--si-fg-red-secondary) }
div.visit-q-cont.orange button.chosen > div.visit-select-c > div { background-color: var(--si-fg-orange-secondary) }
div.visit-q-cont.gold button.chosen > div.visit-select-c > div { background-color: var(--si-fg-gold-secondary) }
div.visit-q-cont.lime button.chosen > div.visit-select-c > div { background-color: var(--si-fg-lime-secondary) }
div.visit-q-cont.green button.chosen > div.visit-select-c > div { background-color: var(--si-fg-green-secondary) }
div.visit-q-cont.turquoise button.chosen > div.visit-select-c > div { background-color: var(--si-fg-aqua-secondary) }
div.visit-q-cont.purple button.chosen > div.visit-select-c > div { background-color: var(--si-fg-purple-secondary) }


div.visit-icon-d {
  width: 100%;
  text-align: center;
  margin: 70px 0 0 0;
}

div.visit-icon-d path.black { fill: var(--si-fg-black-main) }
div.visit-icon-d path.silver { fill: var(--si-fg-silver-main) }
div.visit-icon-d path.navy { fill: var(--si-fg-navy-main) }
div.visit-icon-d path.blue { fill: var(--si-fg-blue-main) }
div.visit-icon-d path.maroon { fill: var(--si-fg-maroon-main) }
div.visit-icon-d path.red { fill: var(--si-fg-red-main) }
div.visit-icon-d path.orange { fill: var(--si-fg-orange-main) }
div.visit-icon-d path.gold { fill: var(--si-fg-gold-main) }
div.visit-icon-d path.lime { fill: var(--si-fg-lime-main) }
div.visit-icon-d path.green { fill: var(--si-fg-green-main) }
div.visit-icon-d path.turquoise { fill: var(--si-fg-aqua-main) }
div.visit-icon-d path.purple { fill: var(--si-fg-purple-main) }

img.thanks-overlay-img {
  height: auto;
  width: 110%;
  overflow: hidden;
  position: absolute;
  bottom: -50px;
  left: -5%;
  z-index: -1;
}
div.visit-q-cont.dark img.thanks-overlay-img { opacity: 0.1; }

div.visit-q-cont > h1.finished {
  font-size: 25px;
  margin: 60px auto 35px auto;
  text-align: center;
  width: 65%;
  line-height: 1.2;
}

div.visit-q-d > h3 {
  font-size: 17px;
  font-weight: 500;
  color: var(--grey-4);
  text-align: center;  
}

div.visit-mng-cont {
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  padding: 40px;
  max-width: 420px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: white;
  transition: left 0.3s ease-in-out;
  -webkit-transition: left 0.3s ease-in-out;
  -moz-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
}
div.visit-mng-cont.off { left: -505px; }

div.visit-mng-cont > img.logo {
  height: 35px;
  width: auto;
  margin: 0 auto 45px 0;
}

div.visit-mng-cont > h1 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 auto 25px 0;
}

div.visit-mng-cont > h1 > span { font-weight: 500; }

div.visit-mng-opt-cont {
  width: 100%;
  height: fit-content;
}

div.visit-mng-opt-cont > button {
  margin: 0 0 15px 0;
  padding: 20px;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: transparent;
  text-align: center;
}
div.visit-mng-opt-cont > button:last-child { margin: 0; }

div.visit-mng-opt-cont > button > img {
  height: 20px;
  margin: 0 auto 8px auto;
}

div.visit-mng-opt-cont > button > h3 {
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: black;
}

button.visit-mng-b {
  position: absolute;
  width: calc(100% - 80px);
  bottom: 40px;
  left: 40px;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: black;
}

button.visit-mng-back-b {
  height: 45px;
  width: 45px;
  margin: 0 auto 39px 0;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: black;
  color: white;
}

label.visit-mng-vst-search {
  display: flex;
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0 0 40px 0;
}
label.visit-mng-vst-search > img {
  position: absolute;
  left: 13px;
  top: 14px;
  bottom: 0;
  width: 13px;
}
label.visit-mng-vst-search > input { 
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 15px 0 35px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}



div.visit-mng-vst-cont {
  height: calc(100% - 258px);
  overflow-y: auto;
}

div.visit-mng-vst-cont > button {
  padding: 0 15px 0 15px;
  width: 100%;
  height: 50px;
  margin: 0 0 15px 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: transparent;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: left;
}
div.visit-mng-vst-cont > button:last-child { margin: 0; }

div.visit-mng-notes {
  height: calc(100% - 132.5px);
}

textarea.visit-mng-ta {
  padding: 15px;
  height: calc(100% - 110px);
  width: calc(100% - 30px);
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: black;
  resize: none;
}

div.visit-mng-notes > button.visit-mng-b {
  position: sticky;
  margin: 30px 0 0 0;
  width: 100%;
}

button.visit-mng-set {
  position: absolute;
  left: 55px;
  bottom: 55px;
  margin: 0;
  z-index: 4;

  width: 50px;
  height: 50px;
  border: 1px solid var(--grey-6);
  border-radius: 5px;
  background-color: transparent;
}




/* Infobase Dashboard */

div.ifb-dsh-cont {
  padding: 70px;
  min-height: calc(100% - 140px);
  height: auto;
  background-color: var(--grey-1);
  z-index: 1;
}

div.ifb-dsh-tools {
  width: 100%;
  margin-top: 20px;
}

div.ifb-dsh-tools > input {
  width: calc(100% - 145px);
  margin-right: 20px;

  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.ifb-dsh-tools > button {
  width: 125px;
  border: none;
  border-radius: 5px;
  padding: 12px 0 12px 0;
  background-color: black;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

div.ifb-dsh-tools > button > img {
  margin-right: 8px;
}

button.ifb-dsh-card {
  width: 100%;
  height: 50px;
  border: 1px solid var(--grey-3);
  background-color: white;
  border-radius: 5px;
  margin: 0 0 15px 0;
  display: flex;
}

button.ifb-dsh-card:last-child {
  margin: 0;
}

button.ifb-dsh-card > h2 {
  margin: auto 0 auto 20px;
  font-size: 15px;
  font-weight: 400;
}

button.ifb-dsh-card > div {
  margin: auto 20px auto auto;
}

button.ifb-dsh-card > div > img {
  margin-right: 8px;
}

button.ifb-dsh-card > div > h3 {
  font-size: 15px;
  color: var(--grey-4);
  margin: 0;
  font-weight: 400;
}

div.ifb-edit-cont {
  padding: 40px 70px 0 70px;
}

button.ifb-edit-back-b {
  height: 45px;
  width: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: black;
  color: white;
}

button.ifb-edit-back-b > img {
  margin-top: 2px;
}

h1.ifb-edit-nm {
  font-size: 20px;
  font-weight: 600;
  margin: 30px 0 0 0;
}

div.ifb-edit-type {
  margin: 5px 0 0 0;
}

div.ifb-edit-type > img {
  margin: 0 8px 0 0;
}

div.ifb-edit-type > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 0;
}

input.ifb-edit-title {
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 0 15px 0 15px;
  height: 50px;
  width: calc(100% - 30px);
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

div.ifb-edit-email-d > h2 {
  margin-bottom: 5px;
}

div.lst-new-i-d > div.ifb-edit-email-title {
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 15px;
  height: 20px;
  width: calc(100% - 30px);
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

div.lst-new-i-d > div.ifb-edit-email-msg {
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 15px;
  height: 350px;
  width: calc(100% - 30px);
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  white-space: pre-wrap;
}

div.ifb-edit-slct-d > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.ifb-edit-slct-d > a, div.ifb-edit-slct-d > button {
  margin: 0 0 0 7px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: var(--orange-accent);
  border: none;
  background-color: transparent;
  text-decoration: underline;
  text-align: left;
  padding: 0;
}


/* Leads - Home */

div.lds-card-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 20px;
}

button.lds-card-b {
  padding: 20px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

button.lds-card-b:only-of-type {
  max-width: calc(50% - 10px);
}

button.lds-card-b > h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: black;
  text-align: left;
}

button.lds-card-b > div {
  margin: 0 0 0 auto;
  text-align: left;
  pointer-events: none;
}

button.lds-card-b > div > img {
  height: 12px;
  margin: auto 6px auto 0;
}

button.lds-card-b > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

button.lds-card-b > div.lds-card-t {
  width: 100%;
  margin: 0;
}

button.lds-card-b > div.lds-card-t > div.lds-card-pf {
  height: 45px;
  width: 45px;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  text-align: center;
  margin: 0 15px 0 0;
}

button.lds-card-b > div.lds-card-t > div.lds-card-pf > h1 {
  margin: 14px 0 0 0;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 700;
  color: white;
}

button.lds-card-b > div.lds-card-t > div.lds-card-nm {
  margin: 0;
  text-align: left;
  width: fit-content;
}

button.lds-card-b > div.lds-card-t > div.lds-card-nm > h2 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'NBIP';
  color: black;
  margin: 0;
}

button.lds-card-b > div.lds-card-t > div.lds-card-nm > div {
  margin: 5px 0 0 0;
}

button.lds-card-b > div.lds-card-t > div.lds-card-nm > div > img {
  margin: 0 7px 0 0;
}

button.lds-card-b > div.lds-card-t > div.lds-card-nm > div > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: var(--grey-6);
}

button.lds-card-b > div.lds-card-spacer {
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background-color: var(--grey-3);
  margin: 15px 0 15px 0;
}

button.lds-card-b > div.lds-card-more {
  width: 100%;
  margin: 0;
}

button.lds-card-b > div.lds-card-more > div.lds-card-place {
  margin: 0 auto 0 0;
}

button.lds-card-b > div.lds-card-more > div.lds-card-date {
  margin: 0 0 0 auto;
}

button.lds-card-b > div.lds-card-more > div > h3 {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: var(--grey-4);
  margin: auto auto auto 8px;
}

div.lds-empty {
  width: 100%;
  height: fit-content;
  padding: 40px 0;
  text-align: center;
  background: linear-gradient(180deg, var(--grey-8) 0%, var(--grey-1) 100%);
  border: none;
  border-radius: 10px 10px 0 0;
}
div.lds-empty > div {
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
}
div.lds-empty > div > h1 {
  margin: 15px auto 0 auto;
  font-size: 17px;
  font-weight: 500;
}
div.lds-empty > div > h3 {
  margin: 0 auto;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: var(--grey-5);
}



/* Leads - Profile */

div.lst-mng-lead-prof-cont.lds {
  padding: 70px;
}

div.lst-mng-lead-prof-cont.lds > h1.lead-prof-nm {
  margin-top: 0px;
}


/* Leads - Export */

div.pop-pg-cont {
  padding: 50px 70px 70px 70px;
}

div.pop-pg-t {
  margin: 30px auto 0 0;
}


/* Manage Listing - Integrations */

div.intg-card-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 15px;
}

button.intg-card-b {
  padding: 17px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  text-align: left;
}

img.intg-card-img {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: none;
}

div.intg-card-id {
  margin: auto auto auto 15px;
  height: auto;
}

div.intg-card-id > h1 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: black;
  text-align: left;
}

div.intg-card-id > h3 {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  color: var(--grey-4);
  text-align: left;
}


/* Manage Listings - Update/Create Integration */

img.intg-mng-img {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  border: none;
  margin: 0 20px 0 0;
}
div.intg-mng-t-d { margin: 0 auto 0 0; }
div.intg-mng-t-d.mt0 { margin-top: 0; }
div.intg-mng-t-id { margin: 0; }
div.intg-mng-t-id.mauto { margin-top: auto; margin-bottom: auto; }
div.intg-mng-t-id.mt { margin-top: 20px; }
div.intg-mng-t-id > h1.lead-prof-nm { margin: 0; }
div.intg-mng-t-id > h3 {
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin: 5px auto 0 0;
}
h3.intg-mng-hs {
  color: var(--grey-4);
  font-size: 15px;
  font-weight: 400;
  margin: -5px 0 15px 0;
}
h2.intg-mng-st {
  color: var(--grey-4);
  font-size: 16px;
  font-weight: 400;
  margin: 3px 0 0 0;
}
div.lst-new-i-d > div.intg-mng-trigger-d {
  height: 40px;
  margin: 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  width: calc(100% - 30px);
  max-width: 350px;
  padding: 0 15px;
  background-color: white;
}
div.intg-mng-trigger-d > h3 {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin: auto auto auto 0;
}
div.intg-mng-trigger-d > img {
  height: 18px;
  margin: auto 10px auto 0;
}
input.intg-mng-in {
  height: 40px;
  margin: 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  width: calc(100% - 30px);
  padding: 0 15px 0 15px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

/* New Question Modal */

div.nqmod-bg {
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  position: fixed;
  background-color: #00000099;
  z-index: 10;
}

div.nqmod-cont {
  width: calc(90% - 40px);
  max-width: 510px;
  height: fit-content;
  margin: 75px auto auto auto;
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 20px;
}

div.nqmod-spacer {
  width: 100%;
  margin: 20px 0 20px 0;
  height: 2px;
  border-radius: 10px;
  background-color: var(--grey-3);
}

div.nqmod-top {
  width: 100%;
  margin: 0;
}

div.nqmod-top > h2 {
  margin: 0 auto 0 0;
  font-size: 15px;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-weight: 600;
  color: black;
}

div.nqmod-top > button:last-child {
  margin: 0 -5px auto auto;
  background-color: transparent;
  border: none;
}

div.nqmod-top > button:last-child > img {
  height: 14px;
  width: 14px;
}

div.nqmod-top > div {
  width: fit-content;
  margin: 0;
}

div.nqmod-top > div > button {
  height: 40px;
  width: 40px;
  margin: 0 15px 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--purple-accent);
}

div.nqmod-top > div > div {
  display: block;
  margin: auto auto auto 0;
}

div.nqmod-top > div > div > h2 {
  margin: 0;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: black;
}

div.nqmod-top > div > div > h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: var(--grey-4);
}

div.nqmod-btm {
  width: 100%;
}

button.nqmod-type-b {
  width: 100%;
  margin: 0 0 10px 0;
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  display: inline-flex;
  background-color: white;
}

button.nqmod-type-b.l {
  margin: 0;
}

button.nqmod-type-b.hidden {
  opacity: 0.5 !important;
}

button.nqmod-type-b.active {
  border-color: var(--purple-accent);
  outline: 1px solid var(--purple-accent);
}

button.nqmod-type-b > img {
  width: 18px;
  margin: auto 10px auto 0;
}

button.nqmod-type-b > h2 {
  margin: 0 auto 0 0;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

button.nqmod-type-b > h3 {
  margin: 0 0 0 auto;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

button.nqmod-lrg-b {
  width: 100%;
  height: 50px;
  margin: 20px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--purple-accent);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
}

div.nqmod-b-d {
  width: 100%;
}

button.nqmod-lrg-b.rmv {
  width: calc(30% - 15px);
  margin: 20px 15px 0 0;
  background-color: black;
  color: white;
}

button.nqmod-lrg-b.sve {
  width: 70%;
}

h3.nqmod-sbt {
  margin: 0 auto 15px 0;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

h3.nqmod-sbt.mt { margin-top: 20px; }

textarea.nqmod-qt-ta, input.nqmod-qopt-in {
  width: calc(100% - 32px);
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  color: black;
}
textarea.nqmod-qt-ta { resize: none; }
input.nqmod-qopt-in { margin-bottom: 10px; }
input.nqmod-qopt-in:last-of-type { margin-bottom: 0; }

textarea.nqmod-qt-ta:focus, input.nqmod-qopt-in:focus {
  outline-color: var(--purple-accent);
}

button.nqmod-qopt-add {
  width: 100%;
  height: 50px;
  margin: 10px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--grey-2);
  color: var(--grey-7);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
}


/* Settings Page */

div.set-mng-cont {
  min-height: 100%;
  height: auto;
  background-color: var(--grey-1);
  z-index: 1;
}

div.set-mng-content {
  padding: 0px 70px 0 70px;
  width: calc(100% - 140px);
  background-color: var(--grey-1);
}

div.set-card-d {
  background-color: transparent;
  width: fit-content;
  max-width: 80%;
  text-align: left;
}

div.set-card-d > div.l > img, div.set-card-d > div.l > div.pfp-plh {
  width: 95px;
  height: 95px;
  margin: 0;
  border: none;
  border-radius: 5px;
}
div.set-card-d > div.l.sm > img, div.set-card-d > div.l.sm > div.pfp-plh {
  width: 75px;
  height: 75px;
}

div.set-card-d > div.l > img {
  background-color: var(--grey-3);
}

div.set-card-d > div.l > div.pfp-plh {
  background-color: var(--orange-accent-lite-2);
  text-align: center;
}

div.set-card-d > div.l > div.pfp-plh > h1 {
  font-size: 32px;
  color: var(--orange-accent);
  margin: 32px auto auto auto;
}
div.set-card-d > div.l.sm > div.pfp-plh > h1 {
  margin-top: 26px;
  font-size: 25px;
}

div.set-card-d > div.r {
  margin: auto auto auto 25px;
  display: block;
}

div.set-card-d > div.r > h2 {
  margin: 0 auto 10px 0;
  font-size: 22px;
  font-family: 'NBIP';
  font-weight: 500;
  color: black;
  text-align: left;
}

div.set-card-d > div.r > div {
  margin: 0 0 5px auto;
  text-align: left;
  pointer-events: none;
}

div.set-card-d > div.r > div:last-child {
  margin-bottom: 0px;
}

div.set-card-d > div.r > div > img {
  margin: auto 10px auto 0;
}

div.set-card-d > div.r > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

div.set-prof-b-d {
  padding: 20px;
  height: 40px;
  width: calc(100% - 40px) !important;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
}

div.set-prof-b-d > div {
  height: fit-content;
  margin: auto auto auto 0;
}

div.set-prof-b-d > div > h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

div.set-prof-b-d > div > h3 {
  margin: 2px 0 0 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.set-prof-b-d > button, button.set-mng-b {
  height: 40px;
  padding: 0 15px 0 15px;
  margin: auto 0 auto auto;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Inter', sans-serif; 
  transition: 0.2s ease-in-out;
  border: none;
  background-color: var(--orange-accent);
  color: white;
}

div.set-prof-b-d > button.del {
  outline: 1px solid var(--delete-fg);
  background-color: var(--delete-bg-fade);
  color: var(--delete-fg);
}

div.set-prof-b-d > button:hover {
  transform: scale(1.05);
}

div.set-int-b-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
}

button.set-int-b {
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  text-align: left;
  display: inline-flex;
}
button.set-int-b.sel { border-color: black; }
button.set-int-b.unsel { opacity: 0.5; }

button.set-int-b > img {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  border: none;
  margin: 0 15px 0 0;
}

button.set-int-b > h2 {
  margin: auto auto auto 0;
  font-size: 17px;
  font-weight: 600;
  color: black;
  text-align: left;
  font-family: 'NBIP';
  font-weight: 500;
}

h3.set-int-hs {
  color: var(--grey-4);
  font-size: 15px;
  font-weight: 400;
  margin: -10px 0 25px 0;
}

button.intg-mng-b {
  width: 100px;
  border: none;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background-color: var(--orange-accent);
}

button.intg-mng-b.hidden { opacity: 0.3; }

label.set-mng-in {
  margin-right: 15px;
  display: flex;
  position: relative;
  flex: 1;
}
label.set-mng-in > img {
  position: absolute;
  left: 13px;
  top: 14px;
  bottom: 0;
  width: 13px;
}
label.set-mng-in > input { 
  width: 100%;
  height: 40px;
  margin: 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 0 15px 0 35px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

div.set-cat-b-cont {
  width: 100%;
  margin: 0 0 15px 0;
}

button.set-cat-b {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 15px;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  margin: 0 0 15px 0;
  opacity: 0.999;
  cursor: pointer;
}

button.set-cat-b > h2 { 
  font-weight: 400;
  font-size: 15px;
  margin: 0 auto 0 8px;
  color: black;
}

button.set-cat-b > div {
  margin: 0 0 0 auto;
  height: 19px;
}

button.set-cat-b > div > img {
  margin: auto 8px auto 0;
  height: 17px;
}

button.set-cat-b > div > h3 {
  font-weight: 400;
  font-size: 15px;
  color: var(--grey-4);
  margin: 0;
}

div.set-cat-mod-bg {
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  position: fixed;
  background-color: #00000099;
  z-index: 10;
}

div.set-cat-mod-cont {
  width: calc(90% - 60px);
  max-width: 510px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 75px auto auto auto;
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 30px;
}

div.set-cat-mod-top {
  width: 100%;
  margin: 0;
}

div.set-cat-mod-top > h2 {
  margin: 0 auto 0 0;
  font-size: 20px;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-weight: 600;
  color: black;
}

div.set-cat-mod-top > button:last-child {
  margin: 0 -5px auto auto;
  background-color: transparent;
  border: none;
}

div.set-cat-mod-top > button:last-child > img {
  height: 14px;
  width: 14px;
}

div.set-cat-mod-spacer {
  width: 100%;
  margin: 20px 0 20px 0;
  height: 2px;
  border-radius: 10px;
  background-color: var(--grey-3);
}

div.set-cat-mod-btm {
  width: 100%;
}

h3.set-cat-mod-sbt {
  margin: 0 auto 15px 0;
  font-size: 15px;
  font-weight: 500;
  color: black;
}
h3.set-cat-mod-sbt.mt { margin-top: 15px; }

input.set-cat-mod-in {
  width: calc(100% - 32px);
  height: fit-content;
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  color: black;
}

input.set-cat-mod-in:focus {
  outline-color: var(--orange-accent);
  border-color: var(--orange-accent)
}

button.set-cat-mod-lrg-b {
  width: 100%;
  height: 50px;
  margin: 20px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
}

button.set-cat-mod-sm-b {
  height: 50px;
  margin: 20px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
}

button.set-cat-mod-sm-b.sv {
  flex: 1;
}

button.set-cat-mod-sm-b.del {
  width: calc(30% - 15px);
  margin-right: 20px;
  color: white;
  background-color: black;
}

div.set-card-d > div.l.org {
  height: 67px;
}

div.set-card-d > div.l.org > img {
  width: 65px;
  height: 65px;
  border: 1px solid var(--grey-3);
}

div.set-card-d > div.r.org {
  margin-left: 20px;
}

div.set-card-d > div.r.org > h2 {
  margin-bottom: 5px;
}

div.set-org-mem-d {
  display: flex;
  height: 60px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  margin: 0 0 10px 0;
}
div.set-org-mem-d:last-child { margin: 0; }

div.set-org-mem-d > div.nm {
  height: 35px;
  width: 35px;
  margin: auto 0 auto 10px;
  background-color: var(--orange-accent-lite-2);
  border: none;
  border-radius: 3px;
  text-align: center;
  display: flex;
}

div.set-org-mem-d > div.nm > h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--orange-accent);
  margin: auto;
}

div.set-org-mem-d > div.dt {
  margin: auto auto auto 12px;
}

div.set-org-mem-d > div.dt > h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-main);
}

div.set-org-mem-d > div.dt > h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: var(--grey-4);
}

div.set-org-mem-d > div.sel {
  width: 100%;
  max-width: 250px;
  margin: auto 10px auto auto;
}

div.set-org-mem-d > button.rmv {
  height: 38px;
  width: fit-content;
  padding: 0 15px;
  margin: auto 10px auto 0;
  border: 1px solid var(--delete-fg);
  border-radius: 5px;
  background-color: var(--delete-bg-fade);
  color: var(--delete-fg);
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

div.set-org-mem-d > h3.role {
  margin: auto 20px auto auto;
  font-size: 15px;
  font-weight: 500;
}

h3.set-org-hs {
  color: var(--grey-4);
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}

h3.set-org-mem-r-hs {
  color: var(--text-main);
  font-size: 15px;
  font-weight: 400;
  margin: auto 15px auto auto;
}

div.set-card-d.mw100 {
  max-width: 100%;
}

div.set-prof-api-d {
  width: calc(100% - 100px);
  max-width: 350px;
  padding: 0 10px 0 10px;
  margin-right: 15px;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.set-prof-api-d > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: auto auto auto 0;
  width: fit-content;
  color: var(--grey-5);
  text-align: left;
}

div.set-prof-api-d > h3 > span {
  color: black;
  font-weight: 500;
}

button.set-prof-api-b {
  width: 80px;
  border: none;
  background-color: var(--orange-accent);
  color: white;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  height: 40px;
  transition: 0.2s ease-in-out;
}
button.set-prof-api-b:hover {
  transform: scale(1.05);
}

div.set-bil-per-slider {
  width: fit-content;
  height: fit-content;
  margin: 0 auto 35px 0;
  cursor: pointer;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}
div.set-bil-per-slider > div {
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;
}
div.set-bil-per-slider > div.sel {
  background-color: var(--orange-accent);
  outline: 1px solid var(--orange-accent);
  border-radius: 3px;
  z-index: 3;
}
div.set-bil-per-slider > div > h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-main);
}
div.set-bil-per-slider > div.sel > h3 { color: white; }

div.set-bil-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
}

div.set-bil-pln-d {
  height: fit-content;
  background-color: white;
  padding: 30px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}
div.set-bil-pln-d.sel { border-color: var(--orange-accent); }

div.set-bil-pln-d > div.top {
  width: 100%;
  height: fit-content;
  margin: 0;
}

div.set-bil-pln-d > div.top > img {
  height: 31px;
  width: 31px;
  margin: 0 auto 0 0;
}

div.set-bil-pln-d > div.top > div {
  width: fit-content;
  height: fit-content;
  margin: 0 0 auto auto;
  padding: 5px 12px;
  background-color: var(--orange-accent-lite-2);
  border-radius: 20px;
}

div.set-bil-pln-d > div.top > div > h3 {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: var(--orange-accent);
  margin: auto;
}

div.set-bil-pln-d > h1 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-main);
  margin: 20px auto 0 0;
}

div.set-bil-pln-d > h3 {
  width: 100%;
  height: fit-content;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.set-bil-pln-d > h2 {
  margin: 20px 0 0 0;
  font-size: 15px;
  font-weight: 600;
  color: black;
}

div.set-bil-pln-d > div.ft {
  width: 100%;
  height: fit-content;
  margin: 10px 0 0 0;
}
div.set-bil-pln-d > div.ft > div {
  margin: 0 0 3px 0;
}
div.set-bil-pln-d > div.ft > div:last-child { margin-bottom: 0px; }
div.set-bil-pln-d > div.ft > div > img {
  margin: auto 10px auto 0;
  height: 8px;
  width: 11px;
}
div.set-bil-pln-d > div.ft > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}


div.set-bil-pln-d > div.pr {
  width: 100%;
  height: fit-content;
  margin: 20px 0 0 0;
}
div.set-bil-pln-d > div.pr > div {
  margin: 0 0 0 auto;
  height: fit-content;
  width: fit-content;
}
div.set-bil-pln-d > div.pr > h2 {
  width: 100%;
  margin: auto auto auto 0;
  font-size: 15px;
  font-weight: 600;
  color: black;
}
div.set-bil-pln-d > div.pr > div > h1 {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  color: var(--text-main);
}
div.set-bil-pln-d > div.pr > div > h1 > span {
  font-size: 18px;
  margin: auto 0 0 5px;
  text-decoration: line-through;
  color: var(--orange-accent);
}
div.set-bil-pln-d > div.pr > div > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 3px 0 0 5px;
  width: 27px;
}
div.set-bil-pln-d > div.pr > div > h3.mo { width: 36px; }

div.set-bil-pln-d > button {
  width: 100%;
  height: 50px;
  margin: 20px 0 0 0;
  border: none;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
div.set-bil-pln-d > button.mng {
  border: 1px solid var(--text-main);
  background-color: white;
  color: var(--text-main);
}
div.set-bil-pln-d > button.upg {
  background-color: var(--orange-accent);
  color: white;
}





/* Automations - Home */

div.atms-card-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
}

button.atms-card-b {
  padding: 17px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  text-align: left;
  display: flex;
}
button.atms-card-b.sel { border-color: black; }
button.atms-card-b.unsel { opacity: 0.5; }

img.atms-card-img {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  border: none;
}

div.atms-card-id {
  margin: auto auto auto 15px;
  height: auto;
}

div.atms-card-id > h1 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: black;
  text-align: left;
}

div.atms-card-id > h3 {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  color: var(--grey-4);
  text-align: left;
}

input.atms-type-in {
  flex: 1;
}

input.atms-name-in {
  width: calc(100% - 30px);
  max-width: 470px;
  height: 40px;
  margin: 0 auto 15px 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  padding: 0 15px 0 15px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
}

button.atms-card-b.sel.rvw {
  width: calc(100% - 30px);
  max-width: 500px;
}

div.atms-icon-sm {
  height: 21px;
  width: 21px;
  border: none;
  border-radius: 3px;
  display: flex;
  margin: 1px 10px auto 0;
}
div.atms-icon-sm.lt { background-color: var(--blue-accent); }
div.atms-icon-sm.ip { background-color: var(--green-accent); }
div.atms-icon-sm.sp { background-color: var(--purple-accent); }

div.atms-icon-sm > img {
  margin: auto;
}

div.atms-lst-t {
  height: fit-content;
  width: fit-content;
  margin: 0 0 15px 0;
}

div.atms-lst-t > h2 {
  font-size: 15px;
  margin: 2px 0 0 0;
  font-weight: 600;
}


/* Automations - Details */

div.lst-new-i-d > h2.ind {
  margin-bottom: 0px;
}

div.lst-new-i-d > h3.ind {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 5px auto 15px 0;
  width: -moz-fit-content;
  width: fit-content;
}

div.lst-new-i-d > input.atm-in, div.lst-new-i-d > textarea.atm-in {
  height: fit-content;
  min-height: 20px;
  width: calc(100% - 30px);
  resize: none;
  flex: 1 1;
  padding: 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.lst-new-i-d > input.atm-in.mb {
  margin-bottom: 15px;
}

div.atm-em-prvw-sbj {
  width: calc(100% - 30px);
  height: 20px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  margin: 0;
  padding: 15px 0 15px 0;
}
div.atm-em-prvw-sbj > h3 {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
  width: fit-content;
  margin: auto auto auto 15px;
}

div.atm-em-prvw-d-cont {
  width: calc(100% - 30px);
  height: fit-content;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  margin: 0;
  padding: 15px 0 15px 0;
}
div.atm-em-prvw-d {
  width: calc(100% - 50px);
  max-width: 615px;
  height: fit-content;
  margin: 25px auto 25px auto;
}
div.atm-em-prvw-d > h2, div.atm-em-prvw-d > h3 {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: black;
  width: fit-content;
  margin: 0;
  margin-bottom: 20px;
  white-space: pre-line;
  overflow: auto;
  overflow-wrap: anywhere;
}
div.atm-em-prvw-d > h2 { 
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 25px;
}
div.atm-em-prvw-d > h2.nm {
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}

div.atm-em-prvw-d > div.prop-i {
  width: 100%;
  height: 200px;
  margin: 0 auto 30px auto;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--grey-2);
  background-image: url("https://listingopen-photos.s3.us-east-2.amazonaws.com/email-preview-cover.jpg");
  background-position: center;
  background-size: cover;
}

div.atm-em-prvw-d > div.prop-i > img {
  width: 100%;
  height: 200px;
}

div.atm-em-prvw-d > img.contact-i {
  height: 65px;
  width: 65px;
  margin: 15px auto -10px 0;
  border: none;
  border-radius: 50%;
  background-color: var(--grey-3);
  object-fit: cover;
  object-position: center;
}

div.atm-em-prs-in, div.atm-em-prs-ta {
  position: relative;
}

div.atm-em-prs-in > div {
  position: absolute;
  height: 22px;
  margin-top: 2px;
  width: calc(100% - 28px);
  padding: 15px;
  font-size: 15px;
  font-family: 'Inter',sans-serif;
  font-weight: 400;
  background-color: transparent;
  pointer-events: none;
  overflow: auto;
  white-space: pre-wrap;
}

div.lst-new-i-d > div.atm-em-prs-in > input {
  width: calc(100% - 30px);
  height: 20px;
  padding: 15px;
  background-color: white;
  color: transparent;
  caret-color: black;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

div.atm-em-prs-ta > div {
  position: absolute;
  margin-top: 1px;
  height: calc(100% - 30px);
  width: calc(100% - 28px);
  padding: 15px;
  font-size: 15px;
  font-family: 'Inter',sans-serif;
  font-weight: 400;
  background-color: transparent;
  pointer-events: none;
  overflow: auto;
  white-space: pre-wrap;
}

div.atm-em-prs-ta > textarea {
  width: calc(100% - 30px);
  height: 20px;
  min-height: 20px;
  padding: 15px;
  background-color: white;
  color: transparent;
  caret-color: black;
  resize: none;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

div.img-mng-gal-i.sq, button.img-mng-gal-add-b.sq {
  width: 115px;
  height: 115px;
}




/* Filters & Export */

button.sel-b {
  width: fit-content;
  height: fit-content;
  margin: 0 auto 6px 0;
  border: none;
  background-color: transparent;
  display: flex;
  padding: 3px 6px 3px 0;
}

button.sel-b > div {
  width: 20px;
  height: 20px;
  border: 1px solid var(--grey-4);
  background-color: transparent;
  border-radius: 11px;
  display: flex;
}
button.sel-b.active > div { border-color: var(--orange-accent); }

button.sel-b > div > div {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 7px;
  background-color: var(--orange-accent);
  margin: auto;
  display: none;
}
button.sel-b.active > div > div { display: flex; }

button.sel-b > h3 {
  color: var(--grey-6);
  font-weight: 400;
  margin: auto 0 auto 15px;
  font-size: 15px;
}

div.filt-lst-d-cont {
  display: flex;
  width: 100%;
  height: fit-content;
}

div.filt-lst-d {
  display: flex;
  flex: 1;
  height: fit-content;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--grey-3);
  background-color: white;
}

div.filt-lst-d > h2 {
  margin: 0 auto 0 10px;
  font-size: 15px;
  font-weight: 400;
}

div.filt-lst-d-cont > button {
  width: fit-content;
  padding: 0 10px 0 10px;
  height: 35px;
  margin: auto 0 auto 15px;
  background-color: var(--delete-bg-fade);
  border-radius: 5px;
  border: 1px solid var(--delete-fg);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: var(--delete-fg);
}

div.filt-q-cont {
  width: 100%;
  height: fit-content;
}

div.filt-q-cont > div {
  width: calc(100% - 32px);
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  margin: 0 0 15px 0;
  cursor: pointer;
}
div.filt-q-cont > div.sel {
  border: 2px solid var(--purple-accent);
  padding: 14px;
}
div.filt-q-cont > div:last-child { margin-bottom: 0; }

div.filt-q-cont > div > div.flex > h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto 0 0;
}

div.filt-q-cont > div > div.flex > div.q-type {
  height: fit-content;
  width: fit-content;
  margin: auto 0 auto auto;
}

div.filt-q-cont > div > div.flex > div.q-type > img {
  margin: auto 8px auto 0;
}

div.filt-q-cont > div > div.flex > div.q-type > h3 {
  font-weight: 400;
  font-size: 15px;
  color: var(--grey-4);
  margin: 0;
}

div.filt-q-cont > div > div.o-cont {
  margin: 15px 0 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

div.filt-q-cont > div > div > button {
  padding: 14px 0 14px 15px;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 400;
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 3px;
  display: flex;
  user-select: none;
}
div.filt-q-cont > div > div > button.sel { border-color: black; }

div.filt-q-cont > div > div > button > div.c-cont {
  width: 17px;
  height: 17px;
  border: 1px solid black;
  border-radius: 10px;
  margin-right: 10px;
}

div.filt-q-cont > div > div > button > div.c-cont > div {
  background-color: white;
  height: 9px;
  width: 9px;
  border-radius: 5px;
  margin: 4px 4px;
}
div.filt-q-cont > div > div > button.sel > div.c-cont > div { background-color: black; }


/* Image Management */

div.img-mng-gal-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-gap: 15px;
}
div.img-mng-gal-cont.edit { margin-top: 20px; }

button.img-mng-gal-del-b {
  margin-left: -18px;
  height: 30px;
  width: 30px;
  margin-top: -12px;
  border: none;
  border-radius: 50%;
  color: white;
  background-color: black;
  font-size: 20px;
  transition: 0.1s ease-in-out;
}
button.img-mng-gal-del-b:hover { transform: scale(1.1); }

div.img-mng-gal-i {
  height: 100px;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

div.img-mng-gal-i > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  background-color: var(--grey-2);
}

button.img-mng-gal-add-b {
  height: 100px;
  width: calc(100% - 12px);
  background-color: white;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

button.img-mng-gal-add-b > img {
  margin: 0 auto 5px auto;
}

button.img-mng-gal-add-b > h2 {
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: var(--grey-4);
}

div.add-img-mod-bg {
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  position: fixed;
  background-color: #00000099;
  z-index: 10;
}

div.add-img-mod-cont {
  width: calc(90% - 60px);
  max-width: 510px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 75px auto auto auto;
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 30px;
}

div.add-img-mod-top {
  width: 100%;
  margin: 0;
}

div.add-img-mod-top > h2 {
  margin: 0 auto 0 0;
  font-size: 20px;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-weight: 600;
  color: black;
}

div.add-img-mod-top > button:last-child {
  margin: 0 -5px auto auto;
  background-color: transparent;
  border: none;
}

div.add-img-mod-top > button:last-child > img {
  height: 14px;
  width: 14px;
}

div.add-img-mod-spacer {
  width: 100%;
  margin: 20px 0 20px 0;
  height: 2px;
  border-radius: 10px;
  background-color: var(--grey-3);
}

div.set-cat-mod-btm {
  width: 100%;
}

button.add-img-mod-sm-b {
  height: 50px;
  margin: 20px 0 0 0;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-accent);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
}

button.add-img-mod-sm-b.sv {
  flex: 1;
}

button.add-img-mod-sm-b.del {
  width: calc(30% - 15px);
  margin-right: 20px;
  color: white;
  background-color: black;
}

div.add-img-mod-prv-d {
  padding: 15px;
  width: calc(100% - 30px);
  height: fit-content;
  background-color: transparent;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.add-img-mod-prv-d > img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
  margin: 0 15px 0 0;
}

div.add-img-mod-prv-d > div {
  display: block;
  height: fit-content;
  width: fit-content;
  margin: auto auto auto 0;
}

div.add-img-mod-prv-d > div > h2, div.add-img-mod-prv-d > div > h3 {
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}

div.add-img-mod-prv-d > div > h3 {
  color: var(--grey-4);
}

button.add-img-mod-b {
  padding: 20px 0 20px 0;
  width: 100%;
  height: fit-content;
  border: 1px dashed var(--grey-3);
  border-radius: 5px;
  text-align: center;
  background-color: transparent;
}

button.add-img-mod-b > img {
  margin: 0 auto 5px auto;
}

button.add-img-mod-b > h2, button.add-img-mod-b > h3 {
  font-weight: 400;
  font-size: 15px;
  margin: 5px auto 0 auto;
  color: var(--grey-4);
}
button.add-img-mod-b > h2 > span { text-decoration: underline; }
button.add-img-mod-b > h3 { font-size: 12px; }


/* AI Tools */

div.ai-tools-card-cont {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 20px;
}

button.ai-tools-card {
  padding: 20px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  text-align: left;
}

button.ai-tools-card > div.ai-tools-card-top {
  width: 100%;
  margin: 0 0 15px 0;
}
button.ai-tools-card > div.ai-tools-card-top > img {
  height: 45px;
  width: 45px;
  background-color: var(--grey-3);
  border: none;
  border-radius: 5px;
  margin: 0 auto 0 0;
}

button.ai-tools-card > div.ai-tools-card-top > div.tag, div.ai-tool-t-d > div.tag {
  border: none;
  border-radius: 25px;
  text-align: center;
  padding: 5px 12px;
  margin: 0 0 auto auto;
}
button.ai-tools-card > div.ai-tools-card-top > div.tag.cr, div.ai-tool-t-d > div.tag.cr { background-color: #C7ECD1; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.en, div.ai-tool-t-d > div.tag.en { background-color: #D4E5FA; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.hi, div.ai-tool-t-d > div.tag.hi { background-color: #F9D57B; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.ex, div.ai-tool-t-d > div.tag.ex { background-color: #EADCFC; }

button.ai-tools-card > div.ai-tools-card-top > div.tag > h3, div.ai-tool-t-d > div.tag > h3 {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
button.ai-tools-card > div.ai-tools-card-top > div.tag.cr > h3, div.ai-tool-t-d > div.tag.cr > h3 { color: #0B6249; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.en > h3, div.ai-tool-t-d > div.tag.en > h3 { color: #0E4FA7; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.hi > h3, div.ai-tool-t-d > div.tag.hi > h3 { color: #72431D; }
button.ai-tools-card > div.ai-tools-card-top > div.tag.ex > h3, div.ai-tool-t-d > div.tag.ex > h3 { color: #6C31AC; }

button.ai-tools-card > h2 {
  margin: 0;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: black;
}

button.ai-tools-card > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.ai-tool-t-d {
  width: 100%;
  height: 25px;
  margin: 35px 0 0 0;
}
div.ai-tool-t-d > div:first-child { margin: 0 auto 0 0; }
div.ai-tool-t-d > div.m0 { margin: 0 !important; }

div.ai-tool-t-d > div > img {
  height: 22px;
  width: 22px;
  margin: 1px 12px auto 0;
  border-radius: 2px;
}

div.ai-tool-t-d > div > h1 {
  font-size: 22px;
  margin: 0 auto 0 0;
}

div.ai-tool-t-d > div:last-child { 
  margin: 0 0 0 auto;
}

div.content-spacer.ai-tool {
  margin-bottom: 30px;
}

div.ai-tool-input {
  width: 100%;
  height: fit-content;
  margin: 0 0 30px 0;
}
div.ai-tool-input.mb0 { margin: 0px; }

div.ai-tool-input > h2 {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-main);
  margin: 0 auto 0 0;
  width: fit-content;
}
div.ai-tool-input > h2.ai-tool-h-othr {
  margin-top: 30px;
}

div.ai-tool-input > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
  margin: 5px auto 0 0;
  width: fit-content;
}

div.ai-tool-input > input, div.ai-tool-input > textarea, div.ai-tool-input > div {
  margin: 15px 0 0 0;
}

div.ai-tool-input > input {
  height: 38px;
  width: calc(100% - 30px);
  flex: 1 1;
  padding: 0px 15px 0px 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.ai-tool-input > textarea {
  height: 200px;
  width: calc(100% - 30px);
  resize: none;
  flex: 1 1;
  padding: 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
}

div.ai-tool-b-d {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
div.ai-tool-b-d.sel { margin-bottom: 30px; }
div.ai-tool-b-d.tone { grid-template-columns: repeat(auto-fit, minmax(185px, 1fr)); }

div.ai-tool-b-d > button {
  padding: 15px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  text-align: left;
  display: inline-flex;
}
div.ai-tool-b-d.tone > button { padding: 10px; }
div.ai-tool-b-d > button.unsel { opacity: 0.5; }
div.ai-tool-b-d > button.sel { border-color: var(--text-main); }

div.ai-tool-b-d > button > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-main);
  margin: auto auto auto 0;
  width: fit-content;
}
div.ai-tool-b-d.tone > button > h3 {
  font-family: 'NBIP', 'Inter', sans-serif;
  font-weight: 500;
  margin-top: 8px;
}
div.ai-tool-b-d > button > img { margin-right: 12px; }

div.ai-tool-b-d > button > div.ai-tool-b-c {
  height: 17px;
  width: 17px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid var(--grey-4);
  border-radius: 10px;
  margin-right: 10px;
}
div.ai-tool-b-d > button > div.ai-tool-b-c.sel { border-color: var(--text-main); }

div.ai-tool-b-d > button > div.ai-tool-b-c > div {
  background-color: transparent;
  border-radius: 10px;
  width: 9px;
  height: 9px;
  margin: 3px;
}
div.ai-tool-b-d > button > div.ai-tool-b-c.sel > div { background-color: var(--text-main); }

div.ai-tool-b-d > button.pds { padding: 0px; }
div.ai-tool-b-d > button.pds.sel { border-color: var(--orange-accent); }
div.ai-tool-b-d > button.pds > h3 {
  margin: auto auto auto 15px;
  color: black;
}
div.ai-tool-b-d > button.pds > input.pds-in {
  margin: 10px 10px 10px auto;
  border-radius: 4px;
  border: none;
  text-align: center;
  padding: 5px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  background-color: var(--grey-2);
  color: var(--grey-4);

  width: 4ch;
  min-width: 4ch;
  max-width: 125px;
  transition: width 0.1s ease-in-out;
}
div.ai-tool-b-d > button.pds.sel > input.pds-in { 
  background-color: var(--orange-accent-lite-2);
  color: var(--orange-accent);
}
div.ai-tool-b-d > button.pds > input.pds-in:focus { outline-color: var(--grey-4); }
div.ai-tool-b-d > button.pds.sel > input.pds-in:focus { outline-color: var(--orange-accent); }

div.ai-fnsh-t-d {
  padding: 20px;
  width: calc(100% - 42px);
  height: fit-content;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  margin: 0;
}

div.ai-fnsh-t-d > h3 {
  font-weight: 400;
  font-size: 17px;
  color: var(--text-main);
  margin: 0;
  width: fit-content;
  height: fit-content;
  white-space: pre-line;
}

div.ai-fnsh-pn-d {
  margin: 0 auto 15px 0;
  width: fit-content;
  height: fit-content;
}

div.ai-fnsh-pn-d > h3 {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-main);
  margin: auto 0 auto 0;
}

div.ai-fnsh-pn-d > button {
  height: 30px;
  width: fit-content;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--text-main);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
div.ai-fnsh-pn-d > button:first-child { padding-right: 10px; }
div.ai-fnsh-pn-d > button:last-child { padding-left: 10px; }

div.ai-fnsh-t-d > h3 > span.cursor {
  display: inline-block;
  width: 8px;
  height: 1em;
  background-color: var(--orange-accent);
  margin-left: 2px;
  margin-bottom: -2px;
  animation: blink 0.5s infinite;
}
div.ai-fnsh-t-d > h3 > span.cursor.enhance { background-color: #4D95F5; }

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}

h2.ai-enh-hm {
  color: #0E4FA7;
  font-size: 15px;
  font-weight: 500;
  margin: 25px auto 10px 0;
}
h2.ai-enh-hm.ind { margin: 1px 0 0 0; }

h2.ai-enh-hs {
  color: #4D95F5;
  font-size: 14px;
  font-weight: 500;
  margin: 25px auto 10px 0;
}
h2.ai-enh-hs.fb { color: var(--grey-4); }

div.ai-fnsh-t-d.fb {
  padding: 15px;
  width: calc(100% - 30px);
  border: none;
  background-color: var(--grey-9);
}
div.ai-fnsh-t-d.fb > h3 { font-size: 15px; }
div.ai-fnsh-t-d.enh {
  width: calc(100% - 40px);
  background-color: #E9F2FC;
  border: none;
}
div.ai-fnsh-t-d.enh > h3 { color: #003276; }

textarea.ai-enh-ta {
  width: calc(100% - 32px);
  height: 150px;
  padding: 15px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  resize: none;
}
textarea.ai-enh-ta:focus { outline-color: #0E4FA7; }

div.ai-enh-h-d {
  margin: 25px 0 10px 0;
  width: 100%;
}

div.ai-enh-h-d > button {
  margin: 0 0 0 auto;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #4D95F5;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

div.ai-enh-h-d > div > img { margin: 0 7px 0 0; }


/* Tool Tips */

div.tool-tip-d {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid var(--orange-accent);
  border-radius: 5px;
  margin: 0 0 25px 0;
}

div.tool-tip-d > img {
  margin: auto 15px auto 15px;
}

div.tool-tip-d > h2 {
  margin: 15px 15px 0 15px;
  font-size: 15px;
  font-weight: 400;
}
div.tool-tip-d.flex > h2 { margin: auto 0 auto 0; }

div.tool-tip-d > button {
  margin: 15px 15px 15px 15px;
  height: 40px;
  background-color: var(--orange-accent);
  color: #ffffff;
  border-radius: 5px;
  border: none;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 0 15px 0 15px;
}
div.tool-tip-d.flex > button { margin: 15px 15px 15px auto; }

div.tool-tip-d > div.top {
  display: flex;
  width: fit-content;
  margin: 20px auto 15px 15px;
}

div.tool-tip-d > div.top > img {
  width: 20px;
  height: 20px;
  margin: 0;
}

div.tool-tip-d > div.top > h1 {
  font-size: 17px;
  line-height: 17px;
  margin: auto 0 auto 10px;
  height: fit-content;
  font-weight: 600;
}

div.tool-tip-d > button.back {
  background-color: transparent;
  border: 1px solid #000000;
  color: #000000;
}

div.tool-tip-d > div.faux-in {
  height: 40px;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  margin: 10px 17px 15px 17px;
}

div.tool-tip-d > div.faux-in > h3 {
  margin: 10px 15px auto 15px;
  font-weight: 400;
  font-size: 15px;
}
div.tool-tip-d > div.faux-in.mb5 { margin-bottom: 5px; }


/* Upgrade Modal */

div.upg-pln-mod-bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffff40;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  user-select: none;
}

div.upg-pln-mod-cont-bg {
  position: relative;
  width: 100%;
  max-width: 900px;
  border-radius: 5px;
  padding: 20px 0;
  margin: auto;
  overflow: hidden;
}

div.upg-pln-mod-cont-bg > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: -1;
  background-color: var(--orange-accent);
}

div.upg-pln-mod-cont {
  padding: 45px;
  height: fit-content;
  width: calc(100% - 90px);
  max-width: 400px;
  border: none;
  border-radius: 3px;
  background-color: white;
  margin: 0 20px 0 auto;
}

div.upg-pln-mod-cont > div > img {
  height: 31px;
  margin: 0;
}

div.upg-pln-mod-cont > h1 {
  margin: 25px auto 0 0;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: var(--text-main);
}

div.upg-pln-mod-cont > h2 {
  margin: 25px auto 0 0;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-main);
}

div.upg-pln-mod-cont > h3 {
  margin: 25px auto 0 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-4);
}

div.upg-pln-mod-cont > div.ft {
  width: 100%;
  height: fit-content;
  margin: 15px 0 0 0;
}

div.upg-pln-mod-cont > div.ft > div { margin: 0 0 3px 0; }
div.upg-pln-mod-cont > div.ft > div:last-child { margin-bottom: 0; }

div.upg-pln-mod-cont > div.ft > div > img {
  margin: auto 10px auto 0;
  height: 8px;
  width: 11px;
}

div.upg-pln-mod-cont > div.ft > div > h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}

div.upg-pln-mod-cont > div.pr {
  width: 100%;
  margin: 25px 0 0 0;
}

div.upg-pln-mod-cont > div.pr > h3 {
  margin: auto auto auto 0;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-main);
}

div.upg-pln-mod-cont > div.pr > div {
  width: fit-content;
  height: fit-content;
  margin: 0 0 0 auto;
}

div.upg-pln-mod-cont > div.pr > div > h2 {
  margin: 0;
  font-family: 'NBIP', 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: var(--text-main);
}

div.upg-pln-mod-cont > div.pr > div > h3 {
  margin: auto 0 2px 5px;
  font-size: 15px;
  font-weight: 500;
  color: var(--grey-4);
}

div.upg-pln-mod-cont > div.bil {
  margin: 15px 0 0 0;
}

div.upg-pln-mod-cont > div.bil > button {
  width: calc(50% - 5px);
  margin: 0 10px 0 0;
  border: 1px solid var(--grey-3);
  border-radius: 5px;
  background-color: transparent;
  height: 50px;
  text-align: left;
}
div.upg-pln-mod-cont > div.bil > button.unsel { opacity: 0.5; }
div.upg-pln-mod-cont > div.bil > button.sel { border-color: var(--text-main); }
div.upg-pln-mod-cont > div.bil > button:last-child { margin-right: 0px; }

div.upg-pln-mod-cont > div.bil > button > h3 {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-main);
  margin: auto auto auto 10px;
}

div.upg-pln-mod-cont > button.upg {
  padding: 0;
  margin: 25px 0 0 0;
  width: 100%;
  height: 50px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background-color: var(--orange-accent);
  border: none;
  border-radius: 5px;
}

div.upg-pln-mod-cont > div > button.close {
  margin: 0 0 0 auto;
  background-color: transparent;
  border: none;
  width: fit-content;
  height: fit-content;
  padding: 0 0 10px 10px;
}